import React, { useState, useEffect } from 'react';
import { Alert, message } from 'antd';
import { fetchProducts, fetchProductVariations, updateProductStock, fetchProductVariation } from '../api';
import InventoryFilters from '../components/Inventory/InventoryFilters';
import InventoryTable from '../components/Inventory/InventoryTable';
import PageHeader from '../components/Common/PageHeader';
import { useAuth } from '../contexts/AuthContext';

const Inventory = () => {
    const { token } = useAuth();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
        },
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [stockFilter, setStockFilter] = useState('all');
    const [variationLoading, setVariationLoading] = useState({});
    const [expandedProducts, setExpandedProducts] = useState([]);

    useEffect(() => {
        fetchProductsData();
    }, [JSON.stringify(tableParams), categoryFilter, stockFilter]);

    const fetchProductsData = async () => {
        setLoading(true);
        try {
            const params = {
                page: tableParams.pagination.current,
                per_page: tableParams.pagination.pageSize,
                category: categoryFilter !== 'all' ? categoryFilter : undefined,
                stock_status: stockFilter !== 'all' ? stockFilter : undefined,
                search: searchQuery,
            };
            const { data, total } = await fetchProducts(token, params);

            const productsWithVariations = await Promise.all(
                data.map(async (product) => {
                    if (product.type === 'variable') {
                        try {
                            const variations = await fetchProductVariations(token, product.id);
                            return {
                                ...product,
                                variations,
                                price_min: Math.min(...variations.map(v => parseFloat(v.regular_price) || 0)),
                                price_max: Math.max(...variations.map(v => parseFloat(v.regular_price) || 0)),
                                total_stock: variations.reduce((sum, v) => sum + (parseInt(v.stock_quantity) || 0), 0)
                            };
                        } catch (error) {
                            console.error(`Error fetching variations for product ${product.id}:`, error);
                            return product;
                        }
                    }
                    return product;
                })
            );

            setProducts(productsWithVariations);
            setTableParams(prev => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    total,
                },
            }));
        } catch (error) {
            setError(error.message);
            message.error('Không thể tải danh sách sản phẩm');
        } finally {
            setLoading(false);
        }
    };

    const fetchVariationsForProduct = async (productId) => {
        if (variationLoading[productId]) return;

        setVariationLoading(prev => ({ ...prev, [productId]: true }));
        try {
            const variations = await fetchProductVariations(token, productId);
            
            setProducts(prevProducts => 
                prevProducts.map(product => 
                    product.id === productId 
                        ? {
                            ...product,
                            variations,
                            price_min: Math.min(...variations.map(v => parseFloat(v.regular_price) || 0)),
                            price_max: Math.max(...variations.map(v => parseFloat(v.regular_price) || 0)),
                            total_stock: variations.reduce((sum, v) => sum + (parseInt(v.stock_quantity) || 0), 0)
                        }
                        : product
                )
            );
        } catch (error) {
            console.error(`Error fetching variations for product ${productId}:`, error);
            message.error('Không thể tải thông tin biến thể');
        } finally {
            setVariationLoading(prev => ({ ...prev, [productId]: false }));
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchProductsData();
        }, 500);

        return () => clearTimeout(timer);
    }, [searchQuery]);

    const handleExpand = async (expanded, record) => {
        console.log('Expanding product:', record.id, expanded);
        
        if (expanded && record.type === 'variable') {
            try {
                await fetchVariationsForProduct(record.id);
                setExpandedProducts(prev => [...prev, record.id]);
            } catch (error) {
                console.error('Error expanding product:', error);
                message.error('Không thể tải thông tin biến thể');
            }
        } else {
            setExpandedProducts(prev => prev.filter(id => id !== record.id));
        }
    };

    const handleUpdateStock = async (productId, newStock, variationId = null) => {
        try {
            if (variationId) {
                await updateProductStock(token, productId, newStock, variationId);
                message.success('Đã cập nhật tồn kho biến thể');
                
                // Cập nhật lại thông tin variations của sản phẩm
                await fetchVariationsForProduct(productId);
            } else {
                await updateProductStock(token, productId, newStock);
                message.success('Đã cập nhật tồn kho sản phẩm');
                await fetchProductsData();
            }
        } catch (error) {
            console.error('Error updating stock:', error);
            message.error('Không thể cập nhật tồn kho');
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sorter,
        });
    };

    return (
        <div>
            <PageHeader title="Quản lý kho hàng" />
            
            {error && <Alert message="Lỗi" description={error} type="error" />}
            
            <InventoryFilters 
                searchQuery={searchQuery}
                categoryFilter={categoryFilter}
                stockFilter={stockFilter}
                onSearchChange={setSearchQuery}
                onSearch={fetchProductsData}
                onCategoryChange={setCategoryFilter}
                onStockFilterChange={setStockFilter}
            />
            
            <InventoryTable 
                products={products}
                loading={loading}
                variationLoading={variationLoading}
                tableParams={tableParams}
                expandedRows={expandedProducts}
                onTableChange={handleTableChange}
                onUpdateStock={handleUpdateStock}
                onExpand={handleExpand}
            />
        </div>
    );
};

export default Inventory; 

