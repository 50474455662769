import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Statistic } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';
import FilterSection from '../../components/Analytics/FilterSection';
import AnalyticsCard from '../../components/Analytics/Overvies/AnalyticsCard';


const OrdersAnalytics = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    dateType: 'preset',
    presetDate: 'today',
    startDate: null,
    endDate: null
  });
  const [stats, setStats] = useState({
    totalOrders: 0,
    totalRevenue: 0,
    averageOrderValue: 0,
    averageProductsPerOrder: 0
  });

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Đơn hàng #',
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customer_name',
      key: 'customer_name',
    },
    {
      title: 'Loại khách hàng',
      dataIndex: 'customer_type',
      key: 'customer_type',
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Mục đã bán',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Mã ưu đãi',
      dataIndex: 'coupon_code',
      key: 'coupon_code',
    },
    {
      title: 'Doanh số bán hàng',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (value) => `${value.toLocaleString()} đ`
    },
    {
      title: 'Thuộc tính',
      dataIndex: 'properties',
      key: 'properties',
    }
  ];

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
        
      
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <AnalyticsCard>
            <Statistic 
              title="Số đơn hàng"
              value={stats.totalOrders}
              prefix={<ShoppingCartOutlined />}
            />
          </AnalyticsCard>
        </Col>
        <Col span={6}>
          <AnalyticsCard>
            <Statistic 
              title="Doanh số bán hàng"
              value={stats.totalRevenue}
              suffix="đ"
            />
          </AnalyticsCard>
        </Col>
        <Col span={6}>
          <AnalyticsCard>
            <Statistic 
              title="Giá trị đơn hàng trung bình"
              value={stats.averageOrderValue}
              suffix="đ"
            />
          </AnalyticsCard>
        </Col>
        <Col span={6}>
          <AnalyticsCard>
            <Statistic 
              title="Số lượng SP trung bình/đơn"
              value={stats.averageProductsPerOrder}
              precision={2}
            />
          </AnalyticsCard>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>        
        <Col span={24}>
          <AnalyticsCard title="Chi tiết đơn hàng">
            <Table 
              columns={columns}
              dataSource={data}
              loading={loading}
              rowKey="order_number"
              pagination={{ pageSize: 10 }}
            />
          </AnalyticsCard>
        </Col>
      </Row>
    </div>
  );
};

export default OrdersAnalytics; 