import React from 'react';
import { Input, Select } from 'antd';

const { Search } = Input;
const { Option } = Select;

const UserFilters = ({ 
    searchQuery,
    statusFilter,
    loading,
    onSearchChange,
    onSearch,
    onStatusFilterChange,
    statuses
}) => {
    return (
        <div style={{ marginBottom: '16px' }}>
            <Search 
                placeholder="Search by ID, Name, Email, Phone" 
                value={searchQuery}
                onChange={(e) => onSearchChange(e.target.value)}
                onSearch={onSearch}
                enterButton 
                loading={loading}
                style={{ width: '300px', marginRight: '16px' }}
            />
            <Select 
                value={statusFilter} 
                onChange={onStatusFilterChange} 
                style={{ width: '200px' }}
            >
                <Option value="">All</Option>
                {statuses.map(status => (
                    <Option key={status} value={status}>
                        {status}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default UserFilters; 