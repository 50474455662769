import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import axios from 'axios';
import PageHeader from '../components/Common/PageHeader';
import { UserOutlined } from '@ant-design/icons';

const UserTree = () => {
    const [treeData, setTreeData] = useState(null);

    useEffect(() => {
        // Fetch root user data from API
        const fetchRootData = async () => {
            try {
                const wpApiSettings = { ajax_url: 'https://lotilife.com/wp-admin/admin-ajax.php', nonce: '8624ae572f' };
                const response = await axios.post(wpApiSettings.ajax_url, new URLSearchParams({
                    action: 'aff_get_user_tree',
                    parent_id: 1
                }).toString(), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                if (response.data.success) {
                    const root = {
                        key: '1',
                        title: 'Admin (ID: 1)',
                        children: formatTreeData(response.data.data)
                    };
                    setTreeData([root]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchRootData();
    }, []);

    // Helper function to format data for Ant Design Tree
    const formatTreeData = (nodes) => {
        return nodes.map(node => ({
            key: node.user_id.toString(),
            title: `${node.display_name || node.user_login} (ID: ${node.custom_user_id})`,
            isLeaf: !node.children || node.children.length === 0,
            children: node.children ? formatTreeData(node.children) : undefined
        }));
    };

    // Handle node expansion
    const onLoadData = async ({ key, children }) => {
        if (children) return;

        try {
            const wpApiSettings = { ajax_url: 'https://lotilife.com/wp-admin/admin-ajax.php', nonce: '8624ae572f' };
            const response = await axios.post(wpApiSettings.ajax_url, new URLSearchParams({
                action: 'aff_get_user_tree',
                parent_id: key
            }).toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

            if (response.data.success) {
                setTreeData(origin => updateTreeData(origin, key, formatTreeData(response.data.data)));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Helper function to update tree data
    const updateTreeData = (list, key, children) => {
        return list.map(node => {
            if (node.key === key) {
                return { ...node, children };
            }
            if (node.children) {
                return { ...node, children: updateTreeData(node.children, key, children) };
            }
            return node;
        });
    };

    return (
        <div>
            <PageHeader title="Cây thành viên" />
            {treeData && (
                <Tree
                    loadData={onLoadData}
                    treeData={treeData}
                    style={{ width: '100%' }}
                    showLine={true}
                    showIcon={true}
                    icon={<UserOutlined />}
                />
            )}
        </div>
    );
};

export default UserTree;
