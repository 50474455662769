import React, { useState, useEffect, useMemo } from 'react';
import { Table, Select, Typography, Space, Input, message, Row, Col, Button, Modal } from 'antd';
import { DownloadOutlined, CalculatorOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getSystemCommission, exportToExcel, calculateCommission } from '../api';
import PageHeader from '../components/Common/PageHeader';

const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const formatNumber = (value) => {
  return value ? `${parseFloat(value).toLocaleString()} đ` : '-';
};

const Commission = () => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format('YYYY-MM'));
  const [commissionData, setCommissionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isCalculateModalVisible, setIsCalculateModalVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const getMonthOptions = () => {
    const options = [];
    for (let i = 0; i < 3; i++) {
      const d = dayjs().subtract(i, 'month');
      options.push({
        value: d.format('YYYY-MM'),
        label: `Tháng ${d.format('M YYYY')}`
      });
    }
    return options;
  };

  const monthOptions = getMonthOptions();

  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'ID',
      dataIndex: 'user_id',
      key: 'user_id',
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'user_phone',
      key: 'user_phone',
    },
    {
      title: 'Điểm PV',
      dataIndex: 'pv',
      key: 'pv',
      render: (value) => value || '-',
    },
    {
      title: 'Hoa Hồng',
      dataIndex: 'commission',
      key: 'commission',
      render: formatNumber,
    },
    {
      title: 'Doanh Số',
      dataIndex: 'sales',
      key: 'sales',
      render: formatNumber,
    },
  ];

  useEffect(() => {
    fetchCommissionData(selectedMonth);
  }, [selectedMonth]);

  const fetchCommissionData = async (month) => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
      const response = await getSystemCommission(token, month);
      if (response.success) {
        setCommissionData(response.data);
      } else {
        message.error('Không thể tải dữ liệu hoa hồng: ' + response.message);
      }
    } catch (error) {
      message.error('Lỗi khi tải dữ liệu hoa hồng: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const filteredData = useMemo(() => {
    if (!commissionData?.details) return [];

    const searchTerms = removeAccents(searchText.toLowerCase()).split(' ').filter(term => term);

    return commissionData.details.filter(item => {
      if (item.commission === null || parseFloat(item.commission) <= 0) return false;
      const searchableFields = [
        item.user_id,
        removeAccents(item.name.toLowerCase()),
        item.user_email,
        item.user_phone
      ].filter(Boolean);

      return searchTerms.every(term =>
        searchableFields.some(field => field.includes(term))
      );
    });
  }, [commissionData, searchText]);

  const showCalculateModal = () => {
    setIsCalculateModalVisible(true);
  };

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };

  const handleExportExcel = async () => {
    setIsExportModalVisible(false);
    const token = localStorage.getItem('token');
    try {
      await exportToExcel(token, selectedMonth);
      message.success('Xuất dữ liệu thành công');
    } catch (error) {
      message.error('Lỗi khi xuất dữ liệu: ' + error.message);
    }
  };

  const handleCalculateCommission = async () => {
    setIsCalculateModalVisible(false);
    const token = localStorage.getItem('token');
    try {
      await calculateCommission(token, selectedMonth);
      message.success('Tính hoa hồng thành công');
      // Tải lại dữ liệu sau khi tính hoa hồng
      fetchCommissionData(selectedMonth);
    } catch (error) {
      message.error('Lỗi khi tính hoa hồng: ' + error.message);
    }
  };

  return (    
    <div>
      <PageHeader 
        title="Hoa Hồng Hệ Thống"                         
      />
      <Space direction="vertical" size={16} style={{ marginBottom: 16, width: '100%' }}>
        <Row gutter={16} align="middle">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Select
              style={{ width: '100%' }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {monthOptions.map(option => (
                <Option key={option.value} value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Col>          
          <Col xs={24} sm={24} md={8} lg={12}>
            <Space>
              <Button 
                icon={<DownloadOutlined />} 
                onClick={showExportModal}
              >
                Xuất Excel
              </Button>
              <Button 
                icon={<CalculatorOutlined />} 
                onClick={showCalculateModal}
              >
                Tính hoa hồng
              </Button>
            </Space>
          </Col>
        </Row>
        <Search
          placeholder="Tìm kiếm theo ID, Tên, Email, Số điện thoại"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: '100%', maxWidth: 300 }}
        />
      </Space>
      <Table 
        dataSource={filteredData} 
        columns={columns} 
        loading={loading}
        rowKey="user_id"
        pagination={{ pageSize: 20 }}
        scroll={{ x: 'max-content' }}
      />

      <Modal
        title="Xác nhận tính hoa hồng"
        visible={isCalculateModalVisible}
        onOk={handleCalculateCommission}
        onCancel={() => setIsCalculateModalVisible(false)}
      >
        <p>Tính lại hoa hồng cho tháng {dayjs(selectedMonth).format('MM/YYYY')}?</p>
      </Modal>

      <Modal
        title="Xuất dữ liệu Excel"
        visible={isExportModalVisible}
        onOk={handleExportExcel}
        onCancel={() => setIsExportModalVisible(false)}
      >
        <p>Tải xuống dữ liệu hoa hồng tháng {dayjs(selectedMonth).format('MM/YYYY')}?</p>
      </Modal>
    </div>
  );
};

export default Commission;
