import React from 'react';
import { Table, Button, Select, Modal, Tooltip } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styled from 'styled-components';
import { UserOutlined, ClusterOutlined } from '@ant-design/icons';
dayjs.extend(utc);
dayjs.extend(timezone);

const { confirm } = Modal;

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 4px 8px !important;
    }
`;

const UserTable = ({ 
    users,
    tableParams,
    loading,
    onTableChange,
    onUpdateStatus,
    onViewDetails,
    onViewSystem
}) => {
    const handleStatusChange = (userId, newStatus, oldStatus) => {
        confirm({
            title: 'Bạn có chắc chắn muốn thay đổi trạng thái của người dùng này?',
            content: `Thay đổi trạng thái từ ${oldStatus} sang ${newStatus}`,
            okText: 'Có',
            okType: 'primary',
            cancelText: 'Không',
            onOk() {
                onUpdateStatus(userId, newStatus);
            }
        });
    };

    const columns = [
        {
            title: '#',
            width: 60,
            render: (_, __, index) => (tableParams.pagination.current - 1) * tableParams.pagination.pageSize + index + 1,
        },
        {
            title: 'MÃ NPP',
            dataIndex: ['details', 'custom_user_id'],
            width: 100,            
        },
        {
            title: 'Tên NPP',
            dataIndex: 'display_name',
            width: 200,
        },
        {
            title: 'Email',
            dataIndex: 'user_email',
            width: 250,
        },
        {
            title: 'Số điện thoại',
            dataIndex: ['details', 'user_phone'],
            width: 150,
        },        
        {
            title: 'Trạng thái',
            dataIndex: ['details', 'account_status'],
            width: 150,
            render: (status, record) => (
                <Select
                    value={status}
                    style={{ width: 120 }}
                    onChange={(newStatus) => handleStatusChange(record.ID, newStatus, status)}
                    disabled={loading}
                    dropdownMatchSelectWidth={false}
                >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="pending">Pending</Select.Option>
                    <Select.Option value="deactive">Deactive</Select.Option>
                </Select>
            )
        },
        {
            title: 'Ngày đăng ký',
            dataIndex: 'user_registered',
            width: 120,
            render: (date) => dayjs.utc(date).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:mm')
        },
        {
            title: 'Hành động',
            width: 100,
            fixed: 'right',
            render: (_, user) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Tooltip title="Xem chi tiết">
                        <Button 
                            type="primary"
                            icon={<UserOutlined />}
                            size="small"
                            onClick={() => onViewDetails(user)}
                        />
                    </Tooltip>
                    <Tooltip title="Xem hệ thống">
                        <Button 
                            type="default"
                            icon={<ClusterOutlined />}
                            size="small"
                            onClick={() => onViewSystem(user)}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <StyledTable 
            dataSource={users}
            columns={columns} 
            rowKey="ID" 
            pagination={tableParams.pagination}
            loading={loading}
            onChange={onTableChange}
            scroll={{ x: 1200, y: 600 }}
            bordered
            size="small"
            sticky
        />
    );
};

export default UserTable; 