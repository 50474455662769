import React, { useEffect, useState } from 'react';
import { fetchMembers, updateUser, updateUserStatus, fetchMemberTree } from '../api';
import { Alert, message, Tabs } from 'antd';
import UserFilters from '../components/Users/UserFilters';
import UserTable from '../components/Users/UserTable';
import PageHeader from '../components/Common/PageHeader';
import UserDetailsModal from '../components/Users/UserDetailsModal';
import MemberTreeModal from '../components/Users/MemberTreeModal';
import ExportMembers from '../components/Users/ExportMembers';
import * as XLSX from 'xlsx';

const { TabPane } = Tabs;

const UserPage = () => {
    const [activeTab, setActiveTab] = useState('all');
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [statusFilter, setStatusFilter] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [treeModalVisible, setTreeModalVisible] = useState(false);
    const [selectedUserTree, setSelectedUserTree] = useState(null);
    const [selectedTreeUser, setSelectedTreeUser] = useState(null);

    useEffect(() => {
        fetchUsersList();
    }, [JSON.stringify(tableParams), statusFilter, activeTab]);

    const fetchUsersList = async (search = searchQuery) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const params = {
            page: tableParams.pagination.current,
            per_page: tableParams.pagination.pageSize,
            status: statusFilter,
            search: search,
            type: activeTab === 'all' ? 'all' : 'f0'
        };
        
        try {
            const data = await fetchMembers(token, params);
            if (data.success) {
                setUsers(data.users);
                setTotalUsers(data.total);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: data.total,
                    },
                });
            } else {
                setError('Failed to fetch users');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
        setStatusFilter('');
        setSearchQuery('');
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const handleStatusFilterChange = (value) => {
        setStatusFilter(value);
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
    };

    const handleSearchChange = (value) => {
        setSearchQuery(value);
        if (value === '') {
            // Nếu xóa hết nội dung tìm kiếm, quay lại trạng thái mặc định
            setTableParams(prev => ({
                ...prev,
                pagination: {
                    ...prev.pagination,
                    current: 1,
                },
            }));
            fetchUsersList('');
        }
    };

    const handleSearch = (value) => {
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
        fetchUsersList(value);
    };

    const handleUpdateUser = async (userId, updateData) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await updateUser(token, userId, updateData);
            
            if (response.success) {
                message.success('Cập nhật thành công');
                setDetailsModalVisible(false);
                fetchUsersList(); // Refresh data
                
                // Cập nhật selectedUser với dữ liệu mới
                setSelectedUser(response.user);
            } else {
                message.error(response.message || 'Cập nhật thất bại');
            }
        } catch (error) {
            console.error('Error updating user:', error);
            message.error(error.message || 'Có lỗi xảy ra khi cập nhật');
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateStatus = async (userId, newStatus) => {
        try {
            const token = localStorage.getItem('token');
            const response = await updateUserStatus(token, userId, newStatus);
            
            if (response.success) {
                message.success('Cập nhật trạng thái thành công');
                fetchUsersList(); // Refresh data
            } else {
                message.error(response.message || 'Cập nhật trạng thái thất bại');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            message.error(error.message || 'Có lỗi xảy ra khi cập nhật trạng thái');
        }
    };

    const handleViewDetails = (user) => {
        setSelectedUser(user);
        setDetailsModalVisible(true);
    };

    const handleViewSystem = async (user) => {
        try {
            setLoading(true);
            
            const token = localStorage.getItem('token');
            const response = await fetchMemberTree(token, user.ID);
            
            if (response.success) {
                setSelectedUserTree(response.tree);
                setSelectedTreeUser(response.user);
                setTreeModalVisible(true);
            } else {
                message.error(response.message || 'Không thể tải dữ liệu hệ thống');
                setSelectedTreeUser(null);
            }
        } catch (error) {
            console.error('Error fetching member tree:', error);
            message.error('Có lỗi xảy ra khi tải dữ liệu hệ thống');
            setSelectedTreeUser(null);
        } finally {
            setLoading(false);
        }
    };

    // Import columnGroups từ ExportMembers hoặc tạo một file riêng để share
    const columnGroups = [
        {
            title: 'Thông tin cơ bản',
            items: [
                { label: 'ID', value: 'ID' },
                { label: 'Tên đăng nhập', value: 'user_login' },
                { label: 'Email', value: 'user_email' },
                { label: 'Họ tên', value: 'display_name' },
                { label: 'Ngày đăng ký', value: 'user_registered' },
                { label: 'Mã NPP', value: 'custom_user_id' },
                { label: 'Số điện thoại', value: 'user_phone' },
                { label: 'Trạng thái', value: 'account_status' },
            ]
        },
        // ... copy các group khác từ ExportMembers
    ];

    const handleExportData = async (type, selectedColumns) => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            
            const params = {
                page: 1,
                per_page: 9999,
                status: '',
                search: '',
                type: type
            };
            
            const data = await fetchMembers(token, params);
            
            if (data.success && data.users) {
                const filteredUsers = data.users.filter(user => 
                    user.ID !== '1' && user.ID !== '404'
                );
                // Tạo map để tra cứu label nhanh
                const columnMap = columnGroups.reduce((acc, group) => {
                    group.items.forEach(item => {
                        acc[item.value] = item.label;
                    });
                    return acc;
                }, {});

                // Tạo headers từ các cột được chọn
                const headers = selectedColumns.map(col => columnMap[col] || col);

                // Chuyển đổi dữ liệu
                const excelData = filteredUsers.map(user => {
                    return selectedColumns.map(col => {
                        switch(col) {
                            // Thông tin cơ bản từ details
                            case 'custom_user_id':
                            case 'user_phone':
                            case 'account_status':                           
                                return user.details?.[col] || '';

                            // Thông tin cá nhân
                            case 'dob':
                            case 'id_number':
                            case 'cccd_issue_date':
                            case 'cccd_issue_place':
                            case 'occupation':
                                return user.details?.personal_info?.[col] || '';

                            // Địa chỉ
                            case 'permanent_address':
                                return user.details?.address?.permanent || '';
                            case 'temporary_address':
                                return user.details?.address?.temporary || '';

                            // Thông tin ngân hàng
                            case 'bank_name':
                                return user.details?.bank?.name || '';
                            case 'bank_branch':
                                return user.details?.bank?.branch || '';
                            case 'bank_account_number':
                                return user.details?.bank?.account_number || '';
                            case 'bank_account_name':
                                return user.details?.bank?.account_name || '';

                            // Thông tin cấp trên
                            case 'parent_id':
                                return user.details?.parent?.id || '';
                            case 'parent_name':
                                return user.details?.parent?.name || '';

                            // Thông tin cơ bản từ user
                            default:
                                return user[col] || '';
                        }
                    });
                });

                // Tạo và xuất file Excel
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([headers, ...excelData]);
                XLSX.utils.book_append_sheet(wb, ws, 'Members');
                XLSX.writeFile(wb, `danh-sach-thanh-vien-${type === 'all' ? 'toan-bo' : 'f0'}.xlsx`);
                
                message.success('Xuất file thành công!');
            }
        } catch (error) {
            console.error('Error exporting:', error);
            message.error('Có lỗi xảy ra khi xuất file');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>            
            <PageHeader title="Quản lý thành viên"
            extra={[
                <ExportMembers fetchData={handleExportData} columnGroups={columnGroups} />
            ]}
             />
            
            {error && <Alert message="Error" description={error} type="error" />}
            
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane 
                    tab={`Tất cả thành viên ${activeTab === 'all' ? `(${totalUsers})` : ''}`} 
                    key="all"
                >
                    <UserFilters 
                        searchQuery={searchQuery}
                        statusFilter={statusFilter}
                        loading={loading}
                        onSearchChange={handleSearchChange}
                        onSearch={handleSearch}
                        onStatusFilterChange={handleStatusFilterChange}
                        statuses={['pending', 'active', 'deactive']}
                    />
                    <UserTable 
                        users={users}
                        tableParams={tableParams}
                        loading={loading}
                        onTableChange={handleTableChange}
                        onUpdateStatus={handleUpdateStatus}
                        onViewDetails={handleViewDetails}
                        onViewSystem={handleViewSystem}
                    />
                </TabPane>
                <TabPane 
                    tab={`Thành viên thiên thần (F0) ${activeTab === 'f0' ? `(${totalUsers})` : ''}`} 
                    key="f0"
                >
                    <UserFilters 
                        searchQuery={searchQuery}
                        statusFilter={statusFilter}
                        loading={loading}
                        onSearchChange={handleSearchChange}
                        onSearch={handleSearch}
                        onStatusFilterChange={handleStatusFilterChange}
                        statuses={['pending', 'active', 'deactive']}
                    />
                    <UserTable 
                        users={users}
                        tableParams={tableParams}
                        loading={loading}
                        onTableChange={handleTableChange}
                        onUpdateStatus={handleUpdateStatus}
                        onViewDetails={handleViewDetails}
                        onViewSystem={handleViewSystem}
                    />
                </TabPane>
            </Tabs>

            <UserDetailsModal 
                visible={detailsModalVisible}
                user={selectedUser}
                onClose={() => setDetailsModalVisible(false)}
                onUpdateUser={handleUpdateUser}
                loading={loading}
            />

            <MemberTreeModal
                visible={treeModalVisible}
                onCancel={() => setTreeModalVisible(false)}
                treeData={selectedUserTree}
                user={selectedTreeUser}
                onViewMember={handleViewSystem}
            />
        </div>
    );
};

export default UserPage;