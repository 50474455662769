import React, { useState } from 'react';
import { Modal, Table, Tag, Input, Button, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';

const UserDetailsModal = ({ visible, user, onClose }) => {
    const [form] = Form.useForm();

    if (!visible) return null;

    if (!user?.user_detail) return null;

    const userDetail = user.user_detail;

    console.log('User Detail:', user);

    const data = [
        {
            key: 'custom_user_id',
            field: 'Mã NPP',
            value: userDetail.custom_user_id,
            editable: false
        },        
        {
            key: 'user_display_name',
            field: 'Tên NPP',
            value: userDetail.user_display_name,
            editable: false
        },
        {
            key: 'user_email',
            field: 'Email',
            value: userDetail.user_email,
            editable: false
        },
        {
            key: 'user_phone',
            field: 'Số điện thoại',
            value: userDetail.user_phone,
            editable: false
        },
        {
            key: 'cccd',
            field: 'CCCD',
            value: userDetail.id_number || "-",
            editable: false
        },
        {
            key: 'cccd_issue_date',
            field: 'Ngày cấp',
            value: userDetail.cccd_issue_date || "-",
            editable: false
        },
        {
            key: 'cccd_issue_place',
            field: 'Nơi cấp',
            value: userDetail.cccd_issue_place || "-",
            editable: false
        },
        {
            key: 'address',
            field: 'Địa chỉ thường trú',
            value: userDetail.address || "-",
            editable: false
        },
        {
            key: 'temporary_address',
            field: 'Địa chỉ tạm trú',
            value: userDetail.temporary_address || "-",
            editable: false
        },
        {
            key: 'bank_name',
            field: 'Tên ngân hàng',
            value: userDetail.bank_name || "-",
            editable: false
        },        
        {
            key: 'bank_account_number',
            field: 'Số tài khoản',
            value: userDetail.bank_account_number || "-",
            editable: false
        },
        {
            key: 'bank_account_name',
            field: 'Tên tài khoản',
            value: userDetail.bank_account_name || "-",
            editable: false
        },
        {
            key: 'bank_branch',
            field: 'Chi nhánh',
            value: userDetail.bank_branch != "undefined" ? userDetail.bank_branch : "-",
            editable: false
        },
        // {
        //     key: 'parent_id',
        //     field: 'ID người giới thiệu',
        //     value: userDetail.parent_id,
        //     editable: false
        // },
        // {
        //     key: 'parent_name',
        //     field: 'Tên người giới thiệu',
        //     value: userDetail.parent_name || "-",
        //     editable: false
        // },                
    ];

    const filteredData = data.filter(item => item.value != null);

    const columns = [
        {
            title: 'Thông tin',
            dataIndex: 'field',
            width: '30%',
        },
        {
            title: '-',
            dataIndex: 'value',
            width: '70%',
            render: (text) => text?.toString() || '-'
        }
    ];

    return (
        <Modal
        title={
            <span>
                Thông tin NPP{' '}
                <strong style={{ color: '#1890ff' }}>
                    {user.customer.first_name} {user.customer.last_name}
                </strong>
            </span>
        }
            open={visible}
            onCancel={onClose}
            width={800}
            footer={null}
        >
            <Form form={form}>
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={false}
                    rowKey="field"
                    size="small"
                    bordered
                />
            </Form>
        </Modal>
    );
};

export default UserDetailsModal;