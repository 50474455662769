import React, { useState, useEffect } from 'react';
import FilterSection from '../components/Analytics/FilterSection';
import { Outlet } from 'react-router-dom';
import PageHeader from '../components/Common/PageHeader';
import { getPerformanceIndicators, getOrderStats, getTopProducts, getOrders } from '../api/reports';
import { message } from 'antd';

const Analytics = () => {   
  const token = localStorage.getItem('token');
  const [filters, setFilters] = useState({
    dateType: 'preset',
    presetDate: 'today',
    startDate: null,
    endDate: null
  });
  const [stats, setStats] = useState(null);
  const [products, setProducts] = useState([]);
  const [orderStats, setOrderStats] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token && filters.startDate && filters.endDate) {
      fetchAllStats();
      fetchProducts();
      fetchOrders();
    }
  }, [token, filters]);

  const fetchAllStats = async () => {
    setLoading(true);
    try {
      const [performanceData, orderData] = await Promise.all([
        getPerformanceIndicators(token, {
          startDate: filters.startDate,
          endDate: filters.endDate
        }),
        getOrderStats(token, {
          startDate: filters.startDate,
          endDate: filters.endDate
        })
      ]);

      setStats(performanceData);
      setOrderStats(orderData);
    } catch (error) {
      console.error('Error:', error);
      message.error('Không thể tải dữ liệu thống kê');
    } finally {
      setLoading(false);
    }
  };
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const data = await getTopProducts(token, filters);
      setProducts(data);
    } catch (error) {
      console.error('Error:', error);
      message.error('Không thể tải danh sách sản phẩm bán chạy');
    } finally {
      setLoading(false);
    }
  };
  const fetchOrders = async () => {
    try {
      setLoading(true);
      const params = {
        ...filters,
      };
      const { data, total } = await getOrders(token, params);
      setOrders(data);
    } catch (error) {
      console.error('Error:', error);
      message.error('Không thể tải danh sách đơn hàng');
    } finally {
      setLoading(false);
    }
  };
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <PageHeader title="Thống kê" />
      <FilterSection onFilterChange={handleFilterChange} />
      <Outlet context={{ filters, token, loading, setLoading, stats, orderStats, products, orders }} />      
    </div>
  );
};

export default Analytics; 