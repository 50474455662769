import React, { useEffect, useState } from 'react'; 
import { Menu, Spin, message, Avatar, Typography } from "antd";
import {  
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  ShopOutlined,  
  BarChartOutlined,
  ShoppingOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../api";

const { Text } = Typography;

const MenuSidebar = ({ setIsLoggedIn, collapsed }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedKey, setSelectedKey] = useState('/');
  const [openKeys, setOpenKeys] = useState([]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      const response = await logout();
      if (response.success) {
        message.success(response.message);
        localStorage.clear();
        setIsLoggedIn(false);
        navigate("/login", { replace: true });
      } else {
        message.error("Lỗi khi đăng xuất. Vui lòng thử lại.");
      }
    } catch (error) {
      console.error("Logout error:", error);
      message.error(`Có lỗi xảy ra: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Menu items configuration
  const menuItems = [
    {
      key: 'analytics',
      icon: <BarChartOutlined />,
      label: 'Thống kê',
      onClick: () => navigate('/analytics'),
      children: [
        {
          key: 'analytics/overview',
          label: 'Tổng quan',
          onClick: () => navigate('/analytics')
        },        
        {
          key: 'analytics/products',
          label: 'Sản phẩm',
          onClick: () => navigate('/analytics/products')
        },
        {
          key: 'analytics/orders',
          label: 'Đơn hàng',
          onClick: () => navigate('/analytics/orders')
        }
      ]
    },
    {
      key: 'users',
      icon: <UserOutlined />,
      label: 'Thành viên',
      onClick: () => navigate('/users'),
      children: [
        {
          key: 'users/list',
          label: 'Danh sách',
          onClick: () => navigate('/users')
        },
        {
          key: 'users/tree',
          label: 'Cây thành viên',
          onClick: () => navigate('/users/tree')
        }
      ]
    },
    {
      key: 'orders',
      icon: <ShopOutlined />,
      label: 'Đơn hàng',
      onClick: () => navigate('/orders')
    },
    {
      key: 'commission',
      icon: <DollarOutlined />,
      label: 'Hoa Hồng',
      onClick: () => navigate('/commission')
    },
    {
      key: 'inventory',
      icon: <ShoppingOutlined />,
      label: 'Kho hàng',
      onClick: () => navigate('/inventory')
    },
    {
      key: 'setting',
      icon: <SettingOutlined />,
      label: 'Setting',
      onClick: () => navigate('/setting')
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: loading ? <Spin size="small" /> : "Đăng xuất",
      onClick: handleLogout
    }
  ];

  useEffect(() => {
    const path = location.pathname;
    const pathParts = path.split('/').filter(Boolean);
    
    if (pathParts.length > 0) {
      setSelectedKey(pathParts.join('/'));
      setOpenKeys([pathParts[0]]);
    } else {
      setSelectedKey('dashboard');
    }
  }, [location]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const UserProfile = () => (
    <div style={{
      padding: collapsed ? '16px 8px' : '20px 16px',
      textAlign: 'center',
      borderBottom: '1px solid #f0f0f0',
      transition: 'all 0.3s'
    }}>
      <Avatar
        size={collapsed ? 32 : 64}
        icon={<UserOutlined />}
        src={localStorage.getItem('userAvatar')}
        style={{ transition: 'all 0.3s' }}
      />
      {!collapsed && (
        <div style={{ 
          marginTop: '8px',
          transition: 'all 0.3s',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}>
          <Text strong>{localStorage.getItem('user_login') || 'Admin'}</Text>
          <br />
          <Text type="secondary">
            {localStorage.getItem('roles')==='administrator' ? 'Quản trị viên' :""}
          </Text>            
        </div>
      )}
    </div>
  );

  const handleMenuClick = ({ key }) => {
    const findMenuItem = (items) => {
      for (const item of items) {
        if (item.key === key) return item;
        if (item.children) {
          const found = findMenuItem(item.children);
          if (found) return found;
        }
      }
      return null;
    };

    const menuItem = findMenuItem(menuItems);
    if (menuItem && menuItem.onClick) {
      menuItem.onClick();
    }
  };

  return (
    <>
      <UserProfile />
      <Menu 
        theme="light" 
        selectedKeys={[selectedKey]} 
        openKeys={!collapsed ? openKeys : []}
        onOpenChange={onOpenChange}
        onClick={handleMenuClick}
        mode="inline"
        inlineCollapsed={collapsed}
        items={menuItems}
      />
    </>
  );
};

export default MenuSidebar;
