import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Space, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import locale from 'antd/es/date-picker/locale/vi_VN';

const { RangePicker } = DatePicker;

const FilterSection = ({ onFilterChange }) => {
  const [dateType, setDateType] = useState('preset');
  const [dateRange, setDateRange] = useState(null);
  const [presetDate, setPresetDate] = useState('today');

  const presetRanges = {
    today: {
      label: 'Hôm nay',
      range: () => [dayjs().startOf('day'), dayjs().endOf('day')]
    },
    yesterday: {
      label: 'Hôm qua',
      range: () => [dayjs().subtract(1, 'day').startOf('day'), dayjs().subtract(1, 'day').endOf('day')]
    },
    thisWeek: {
      label: 'Đầu tuần đến hôm nay',
      range: () => [dayjs().startOf('week'), dayjs().endOf('day')]
    },
    lastWeek: {
      label: 'Tuần trước',
      range: () => [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')]
    },
    thisMonth: {
      label: 'Từ đầu tháng đến nay',
      range: () => [dayjs().startOf('month'), dayjs().endOf('day')]
    },
    lastMonth: {
      label: 'Tháng trước',
      range: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
    },
    thisQuarter: {
      label: 'Từ đầu quý đến nay',
      range: () => [dayjs().startOf('quarter'), dayjs().endOf('day')]
    },
    lastQuarter: {
      label: 'Quý trước',
      range: () => [dayjs().subtract(1, 'quarter').startOf('quarter'), dayjs().subtract(1, 'quarter').endOf('quarter')]
    },
    thisYear: {
      label: 'Từ đầu năm đến nay',
      range: () => [dayjs().startOf('year'), dayjs().endOf('day')]
    },
    lastYear: {
      label: 'Năm ngoái',
      range: () => [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')]
    }
  };

  const handleDateTypeChange = (value) => {
    setDateType(value);
    if (value === 'preset') {
      const range = presetRanges[presetDate].range();
      onFilterChange({
        dateType: 'preset',
        presetDate,
        startDate: range[0].format('YYYY-MM-DD'),
        endDate: range[1].format('YYYY-MM-DD')
      });
    }
  };

  const handlePresetChange = (value) => {
    setPresetDate(value);
    const range = presetRanges[value].range();
    onFilterChange({
      dateType: 'preset',
      presetDate: value,
      startDate: range[0].format('YYYY-MM-DD'),
      endDate: range[1].format('YYYY-MM-DD')
    });
  };

  const handleCustomRangeChange = (dates) => {
    if (dates) {
      setDateRange(dates);
      onFilterChange({
        dateType: 'custom',
        startDate: dates[0].format('YYYY-MM-DD'),
        endDate: dates[1].format('YYYY-MM-DD')
      });
    }
  };

  // Khởi tạo giá trị mặc định
  useEffect(() => {
    const range = presetRanges.today.range();
    onFilterChange({
      dateType: 'preset',
      presetDate: 'today',
      startDate: range[0].format('YYYY-MM-DD'),
      endDate: range[1].format('YYYY-MM-DD')
    });
  }, []);

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
      <Col span={24}>
        <Card bodyStyle={{ padding: '12px 24px' }}>
          <Space size="large">
            <Select
              value={dateType}
              onChange={handleDateTypeChange}
              style={{ width: 150 }}
            >
              <Select.Option value="preset">Cài đặt trước</Select.Option>
              <Select.Option value="custom">Tùy chỉnh</Select.Option>
            </Select>

            {dateType === 'preset' ? (
              <Select
                value={presetDate}
                onChange={handlePresetChange}
                style={{ width: 200 }}
              >
                {Object.entries(presetRanges).map(([key, { label }]) => (
                  <Select.Option key={key} value={key}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <RangePicker
                value={dateRange}
                onChange={handleCustomRangeChange}
                format="DD/MM/YYYY"
                locale={locale}
              />
            )}
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default FilterSection; 