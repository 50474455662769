import React, { useMemo } from 'react';
import { Modal, Tree, Tag, Row, Col, Card, Statistic,Tooltip } from 'antd';
import { UserOutlined, TeamOutlined, PhoneOutlined, MailOutlined, IdcardOutlined } from '@ant-design/icons';
import { getStatusColor } from '../../utils';
const MAX_LEVEL = 7; // Giới hạn 7 cấp

const MemberTreeModal = ({ visible, onCancel, treeData = [], user, onViewMember }) => {
    const countMembersByLevel = (node) => {
        const counts = {};
        
        const traverse = (currentNode, level = 1) => {
            if (!currentNode.children || currentNode.children.length === 0 || level > MAX_LEVEL) return;
            
            counts[level] = currentNode.children.length;
            currentNode.children.forEach(child => traverse(child, level + 1));
        };
        
        traverse(node);
        return counts;
    };

    const treeNodeTitleRender = (nodeData) => {
        const memberCounts = countMembersByLevel(nodeData);
        const hasMembers = Object.keys(memberCounts).length > 0;

        return (
            <div 
                style={{ display: 'inline-flex', alignItems: 'center' }}
                onClick={(e) => {
                    // Ngăn chặn sự kiện click lan ra Tree node
                    e.stopPropagation();
                }}
            >
                <span style={{ cursor: 'pointer' }} onClick={() => onViewMember( {ID: nodeData.user_id, display_name: nodeData.display_name, custom_user_id: nodeData.custom_user_id, account_status: nodeData.account_status})}>
                    <UserOutlined style={{ marginRight: 8 }} />
                    <span style={{ marginRight: 8 }}>
                        {nodeData.display_name}
                    </span>
                    <span style={{ marginRight: 8, color: '#666' }}>
                        ({nodeData.custom_user_id})
                    </span>
                </span>
                <Tag color={getStatusColor(nodeData.account_status)}>
                    {nodeData.account_status}
                </Tag>
                {hasMembers && (
                    <Tooltip title={
                        <div>
                            {Object.entries(memberCounts).map(([level, count]) => (
                                <div key={level}>
                                    Cấp {level}: {count} thành viên
                                </div>
                            ))}
                        </div>
                    }>
                        <Tag icon={<TeamOutlined />} color="processing">
                            {Object.values(memberCounts).reduce((a, b) => a + b, 0)} thành viên
                        </Tag>
                    </Tooltip>
                )}
            </div>
        );
    };
    
    const systemStats = useMemo(() => {
        const stats = {
            total: 1, // Tính cả user hiện tại
            levels: {}
        };
        
        const countMembers = (nodes, level = 1) => {
            if (!nodes || !Array.isArray(nodes) || level > MAX_LEVEL) return;
            
            nodes.forEach(node => {
                stats.total += 1;
                stats.levels[level] = (stats.levels[level] || 0) + 1;
                if (node.children) {
                    countMembers(node.children, level + 1);
                }
            });
        };

        if (treeData && treeData.length > 0 && treeData[0]?.children) {
            countMembers(treeData[0].children, 1);
        }

        return stats;
    }, [treeData]);

    return (
        <Modal
            title={`Hệ thống của ${user?.display_name || 'Thành viên'}`}
            open={visible}
            onCancel={onCancel}
            footer={null}
            width={800}
        >
            <Row gutter={24} style={{ marginBottom: 16, padding: 16, border: '1px solid #f0f0f0', borderRadius: 4 }}>
                {/* Cột thông tin thành viên */}
                <Col span={12}>
                    <div style={{ marginBottom: 8 }}>
                        <strong>Thông tin NPP:</strong>
                    </div>
                    <div style={{ marginLeft: 16 }}>
                    <div style={{ marginBottom: 8 }}>
                            <IdcardOutlined style={{ marginRight: 8 }} />
                            Cấp bậc: {user?.details?.level || "NEW LEADER"}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <UserOutlined style={{ marginRight: 8 }} />
                            Họ tên: {user?.display_name}
                            <Tag style={{ marginLeft: 8 }} color={getStatusColor(user?.details?.account_status)}>
                                {user?.details?.account_status}
                            </Tag>
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <IdcardOutlined style={{ marginRight: 8 }} />
                            Mã NPP: {user?.details?.custom_user_id}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <PhoneOutlined style={{ marginRight: 8 }} />
                            SĐT: {user?.details?.user_phone}
                        </div>
                        <div style={{ marginBottom: 8 }}>
                            <MailOutlined style={{ marginRight: 8 }} />
                            Email: {user?.user_email}
                        </div>
                    </div>
                </Col>

                {/* Cột thông tin cấp trên */}
                <Col span={12}>
                    <div style={{ marginBottom: 8 }}>
                        <strong>Cấp trên:</strong>
                    </div>
                    <div style={{ marginLeft: 16 }}>
                        {user?.details?.parent ? (
                            <>
                                <div style={{ marginBottom: 8 }}>
                                    <UserOutlined style={{ marginRight: 8 }} />
                                    Họ tên: {user.details.parent.name}
                                </div>
                                <div style={{ marginBottom: 8 }}>
                                    <IdcardOutlined style={{ marginRight: 8 }} />
                                    Mã NPP: {user.details.parent.custom_user_id}
                                </div>
                            </>
                        ) : (
                            <div>Không có</div>
                        )}
                    </div>
                </Col>
            </Row>

            {/* Thống kê hệ thống */}
            <Card 
                title="Thống kê Thành viên" 
                style={{ marginBottom: 16 }}
                bodyStyle={{ padding: '10px 20px' }}
                extra={<div style={{ color: '#1890ff' }}>Tổng số thành viên: {systemStats.total}</div>}
            >
                <Row gutter={[16, 16]}>                    
                    {Object.entries(systemStats.levels).map(([level, count]) => (
                        <Col span={8} key={level}>
                            <Statistic
                                title={`Cấp ${level}`}
                                value={count}
                                suffix="thành viên"
                            />
                        </Col>
                    ))}
                </Row>
            </Card>

            {/* Tree */}
            <Card title="Sơ đồ hệ thống">
                <Tree
                    showLine={{ showLeafIcon: false }}
                    defaultExpandAll
                    titleRender={treeNodeTitleRender}
                    treeData={treeData}
                />
            </Card>
        </Modal>
    );
};

export default MemberTreeModal; 