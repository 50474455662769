import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Radio, Checkbox, Select, DatePicker, Button, InputNumber, Card, Descriptions, Space, Typography } from 'antd';
import { getPickupAddresses } from '../../../api/ghtkService';
import { formatCurrency } from '../../../utils/format';
const { Title, Text } = Typography;

const GHTKOrderModal = ({ visible, onCancel, onSubmit, orderData, loading }) => {    
    const [form] = Form.useForm();
    const [pickupAddresses, setPickupAddresses] = useState([]);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
     // State lưu thông tin đơn hàng GHTK
     const [ghtkOrder, setGhtkOrder] = useState({
        pick_name: "LK TRẦN", 
        pick_address: "Lô B Chung Cư Bàu Cát 2, Phường 10, Quận Tân Bình, TP Hồ Chí Minh",
        pick_address_id: "2018204",
        pick_province: "TP. Hồ Chí Minh",
        pick_district: "Quận Tân Bình",
        pick_ward: "Phường 10",
        pick_tel: "0981864257",        
        hamlet: '',
        is_freeship: 0,
        pick_money: 0,
        note: '',
        value: 0,
        transport: 'road',
        weight: 0,
        deliver_option: 'shipper_pickup',
        shipping_payment: 'shop_pay',
        shipping_total: 0,
        is_cod_transferred: true,
    });
    useEffect(() => {
        if (visible && orderData) {
            fetchPickupAddresses();
            // Tạo object chứa dữ liệu form từ orderData
        const formData = {
            name: `${orderData?.billing?.first_name} ${orderData?.billing?.last_name}`,
            tel: orderData?.billing?.phone,
            address: orderData?.billing?.address_1,
            province: orderData?.billing?.state,
            district: orderData?.billing?.city,
            ward: orderData?.billing?.address_2,
            weight: Math.round(
                (orderData?.line_items?.reduce((total, item) => 
                    total + ((item.quantity * 0.5) * 0.8), 0) || 0) * 100
            ) / 100,
            shipping_total: orderData?.shipping_total || 0,
            id: orderData?.id,
            insurance_value: 0,
        };
            form.setFieldsValue(formData);
            // Lấy giá trị hiện tại của form
            const formValues = form.getFieldsValue();
            
            setGhtkOrder(prevState => ({
                ...prevState,           // Giữ lại các giá trị mặc định
                ...orderData,           // Cập nhật từ orderData
                id: orderData?.id,
                shipping_total: orderData?.shipping_total || 0,
                ...formValues,          // Ưu tiên giá trị từ form
            }));    
        setGhtkOrder(prevState => ({
            ...prevState,                    // Giữ các giá trị mặc định
            ...formData,                     // Cập nhật với dữ liệu form mới
            pick_name: pickupAddresses[0]?.pick_name || prevState.pick_name,
            pick_address: pickupAddresses[0]?.value || prevState.pick_address,
            pick_address_id: pickupAddresses[0]?.id || prevState.pick_address_id,
            pick_province: pickupAddresses[0]?.province || prevState.pick_province,
            pick_district: pickupAddresses[0]?.district || prevState.pick_district,
            pick_ward: pickupAddresses[0]?.ward || prevState.pick_ward,
            pick_tel: pickupAddresses[0]?.pick_tel || prevState.pick_tel,
        }));
        }
    }, [visible, orderData, pickupAddresses]);

    const fetchPickupAddresses = async () => {
        try {
            setLoadingAddresses(true);
            const result = await getPickupAddresses();
            if (result.success) {
                setPickupAddresses(result.data);
                if (result.data.length > 0) {
                    const defaultAddress = result.data[0];
                    form.setFieldsValue({
                        pick_address_id: defaultAddress.id,
                        address: defaultAddress.value,
                        pick_name: defaultAddress.pick_name,
                        pick_tel: defaultAddress.pick_tel
                    });
                }
            }
        } catch (error) {
            console.error('Lỗi khi lấy danh sách địa chỉ kho:', error);
        } finally {
            setLoadingAddresses(false);
        }
    };

    const handlePickupAddressChange = (value) => {
        const selectedAddress = pickupAddresses.find(addr => addr.value === value);
        if (selectedAddress) {
            form.setFieldsValue({
                pick_name: selectedAddress.pick_name,
                pick_tel: selectedAddress.pick_tel
            });
        }
    };


    // Add form change handler
    const handleFormValuesChange = (changedValues, allValues) => {
         // Nếu is_cod_transferred thay đổi và được check
    if ('is_cod_transferred' in changedValues) {
        if (changedValues.is_cod_transferred) {
            // Cập nhật cả form và state
            form.setFieldValue('pick_money', 0);
            setGhtkOrder(prevState => ({
                ...prevState,
                pick_money: 0,
                is_cod_transferred: true
            }));
            return;
            }
        }
        setGhtkOrder(prevState => ({
            ...prevState,
            ...changedValues,
            // Map specific field transformations
            tel: allValues.phone,
            pick_money: allValues.pick_money || 0,
            transport: allValues.transport || 'road',
            is_freeship: allValues.shipping_payment === 'shop_pay' ? 1 : 0,
            value: allValues.insurance_value || 0,
        }));
    };

    return (
        <Modal
            title={<Title level={4}>ĐĂNG ĐƠN LÊN GHTK</Title>}
            visible={visible}
            onCancel={onCancel}
            width={1100}
            style={{ top: 20 }}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Hủy đăng đơn
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    loading={loading} 
                    onClick={() => {
                        form.validateFields()
                            .then(() => {
                                onSubmit(ghtkOrder);
                            })
                            .catch(info => {
                                console.error('Validate Failed:', info);
                            });
                    }}
                >
                    Đăng ngay
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onValuesChange={handleFormValuesChange}
                initialValues={ghtkOrder}
                style={{ maxHeight: '80vh', overflowY: 'auto',marginBottom: 0 }}
            >
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                    {/* Left Column */}
                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                        <Card size="small" title="Cửa hàng/kho lấy hàng" bordered={false}>
                            <Form.Item name="pickup_address">
                                <Select
                                    loading={loadingAddresses}
                                    onChange={handlePickupAddressChange}
                                    placeholder="Chọn địa chỉ lấy hàng"
                                >
                                    {pickupAddresses.map(address => (
                                        <Select.Option key={address.value} value={address.value}>
                                            {address.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Card>

                        <Card padding={0} size="small" title="Thông tin đơn hàng" bordered={false}>
                            <Descriptions column={1}>
                                <Descriptions.Item label="Giá trị đơn hàng">
                                    { formatCurrency(form.getFieldValue('total') || orderData?.total || 0) }
                                </Descriptions.Item>
                                <Descriptions.Item label="Phí ship">
                                    <Space direction="vertical" size={0}>
                                        <Text>{ formatCurrency(form.getFieldValue('shipping_total') || orderData?.shipping_total || 0) }</Text>
                                        <Text type="secondary" style={{ fontSize: '12px' }}>
                                            (Phí ship này là có thể không phải của GHTK. Khi đăng đơn lên GHTK phí sẽ được tính lại)
                                        </Text>
                                    </Space>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>

                        <Card size="small" title="Thông tin người nhận hàng" bordered={false}>
                            <Descriptions column={1} bordered>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="SĐT">
                                    <Form.Item name="tel" rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]} noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="Họ tên">
                                    <Form.Item name="name"  noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="Tên thôn/ấp/xóm/tổ...">
                                    <Form.Item name="hamlet"  noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="Địa chỉ">
                                    <Form.Item name="address" noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="Xã/phường">
                                    <Form.Item name="ward" noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="Quận/huyện">
                                    <Form.Item name="district" noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item style={{padding: '8px 12px'}} label="Tỉnh/thành phố">
                                    <Form.Item name="province" noStyle><Input /></Form.Item>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Space>

                    {/* Right Column */}
                    <Card 
                        size="small" 
                        title={
                            <Space direction="vertical" size={0}>
                                <Text>Thông tin đăng đơn lên GHTK</Text>
                                <Text type="danger">(Chỉnh sửa thông số sẽ ảnh hưởng tới phí ship hiện tại)</Text>
                            </Space>
                        }
                        bordered={false}
                    >
                        <Space direction="vertical" size="small" style={{ width: '100%' }}>
                            <Form.Item style={{marginBottom: 0}} label="Phí ship" name="shipping_payment">
                                <Radio.Group>
                                    <Radio value="customer_pay">Khách trả</Radio>
                                    <Radio value="shop_pay">Shop trả</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item style={{marginBottom: 0}} label="Tiền thu hộ" name="pick_money">
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item name="is_cod_transferred" valuePropName="checked">
                                <Checkbox>Khách đã chuyển khoản thì thu hộ = 0</Checkbox>
                            </Form.Item>
                        
                            <Form.Item style={{marginBottom: 0}} label="Gửi hàng tại bưu cục?" name="deliver_option">
                                <Radio.Group>
                                    <Radio value="shipper_pickup">Shipper đến lấy hàng</Radio>
                                    <Radio value="post_office">Shop gửi hàng tại bưu cục</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item style={{marginBottom: 0}} label="Hình thức vận chuyển" name="transport">
                                <Radio.Group>
                                    <Radio value="road">Đường bộ</Radio>
                                    <Radio value="fly">Đường bay</Radio>
                                    <Radio value="xfast">xFast</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item 
                                style={{marginBottom: 0}}
                                label="Giá trị hàng hóa" 
                                name="insurance_value"
                                extra="Áp dụng để tính bảo hiểm đơn hàng. Có thể thay đổi để tránh phí bảo hiểm."
                            >
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item 
                                style={{marginBottom: 0}}
                                label="Tổng khối lượng đơn hàng" 
                                name="weight"
                                extra="Nếu để trống hệ thống sẽ tự động lấy khối lượng của các sản phẩm"
                            >
                                <InputNumber style={{ width: '100%' }} addonAfter="kg" />
                            </Form.Item>

                            <Form.Item 
                                style={{marginBottom: 0}}
                                label="Ghi chú cho GHTK khi giao hàng" 
                                name="note"
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Space>
                    </Card>
                </div>
            </Form>
        </Modal>
    );
};

export default GHTKOrderModal; 