import axios from 'axios';

// Cấu hình API của GHTK
const GHTK_API = {
    BASE_URL: 'https://services.giaohangtietkiem.vn/services/',
    TOKEN: "24c5d8a88EfAbdE810E82EC5FcE7567Bc2a15E20",
    SHOP_ID: "S1179840"
};

/**
 * Chuyển đổi dữ liệu đơn hàng sang format của GHTK
 * @param {Object} ghtkOrder - Đơn hàng từ hệ thống
 * @returns {Object} - Dữ liệu đã được format theo yêu cầu của GHTK
 */
const formatOrderDataForGHTK = (ghtkOrder) => {
    // Tính tổng khối lượng từ các sản phẩm
    const totalWeight = ghtkOrder.line_items?.reduce((sum, item) => {
        return sum + (item.weight || 0) * item.quantity;
    }, 0) || 0;

    // Format địa chỉ người nhận
    const shippingAddress = ghtkOrder.billing || {};

    return {
        products: ghtkOrder.line_items?.map(item => ({
            name: item.name,
            weight: (item.quantity * 0.5),
            quantity: item.quantity,
            product_code: item.sku || item.id?.toString()
        })),
        order: {
            id: ghtkOrder.orderId, // Mã đơn hàng
            pick_name: ghtkOrder.pick_name, 
            pick_address: ghtkOrder.pick_address,
            pick_province: ghtkOrder.pick_province,
            pick_district: ghtkOrder.pick_district,
            pick_ward: ghtkOrder.pick_ward,
            pick_tel: ghtkOrder.pick_tel,
            tel: shippingAddress.phone, // Số điện thoại người nhận
            name: `${shippingAddress.first_name} ${shippingAddress.last_name}`, // Tên người nhận
            address: shippingAddress.address_1, // Địa chỉ người nhận
            province: ghtkOrder.province, // Tỉnh/TP người nhận
            district: ghtkOrder.district, // Quận/Huyện người nhận
            ward: ghtkOrder.ward, // Phường/Xã người nhận
            hamlet: ghtkOrder.hamlet || "Khác", // Thôn/Ấp người nhận (để mặc định là Khác)
            is_freeship: 0, // 1: Freeship, 0: Không freeship
            pick_money: ghtkOrder.total, // Tiền thu hộ
            note: ghtkOrder.customer_note || "", // Ghi chú
            value: ghtkOrder.total, // Giá trị đơn hàng
            transport: ghtkOrder.transport || "road", // Phương thức vận chuyển
            weight: ghtkOrder.weight || 0, // Khối lượng (gram)
            deliver_option: ghtkOrder.deliver_option || "none", // Phương thức giao hàng   
            pick_money: ghtkOrder.pick_money || 0, // Tiền thu hộ
        }
    };
};

/**
 * Tạo đơn hàng mới trên GHTK
 * @param {Object} order - Đơn hàng cần tạo
 * @returns {Promise} Kết quả từ GHTK
 */
export const createGHTKOrder = async (ghtkOrder) => {
    try {
        // Format dữ liệu đơn hàng
        const ghtkData = formatOrderDataForGHTK(ghtkOrder);        
        // Gọi API của GHTK
        const response = await axios.post(
            `${GHTK_API.BASE_URL}shipment/order`,
            ghtkData,
            {
                headers: {
                    'Token': GHTK_API.TOKEN,
                    'Content-Type': 'application/json'
                }
            }
        );

        // Xử lý response từ GHTK
        if (response.data.success) {
            return {
                success: true,
                message: 'Đăng đơn hàng lên GHTK thành công',
                data: response.data
            };
        } else {
            throw new Error(response.data.message || 'Lỗi khi tạo đơn hàng GHTK');
        }
    } catch (error) {
        console.error('Lỗi khi tạo đơn hàng GHTK:', error);
        return {
            success: false,
            message: error.message || 'Không thể tạo đơn hàng GHTK',
            error: error
        };
    }
};

/**
 * Lấy thông tin đơn hàng từ GHTK
 * @param {string} orderId - Mã đơn hàng
 * @returns {Promise} Thông tin đơn hàng
 */
export const getGHTKOrderStatus = async (orderId) => {
    try {
        const response = await axios.get(
            `${GHTK_API.BASE_URL}/${orderId}`,
            {
                headers: {
                    'Token': GHTK_API.TOKEN
                }
            }
        );

        if (response.data.success) {
            return {
                success: true,
                data: response.data.order
            };
        } else {
            throw new Error('Không thể lấy thông tin đơn hàng');
        }
    } catch (error) {
        console.error('Lỗi khi lấy thông tin đơn hàng GHTK:', error);
        return {
            success: false,
            message: 'Không thể lấy thông tin đơn hàng',
            error: error
        };
    }
};

/**
 * Hủy đơn hàng trên GHTK
 * @param {string} orderId - Mã đơn hàng cần hủy
 * @returns {Promise} Kết quả hủy đơn
 */
export const cancelGHTKOrder = async (orderId) => {
    try {
        const response = await axios.post(
            `${GHTK_API.BASE_URL}/cancel/${orderId}`,
            {},
            {
                headers: {
                    'Token': GHTK_API.TOKEN
                }
            }
        );

        if (response.data.success) {
            return {
                success: true,
                message: 'Hủy đơn hàng thành công'
            };
        } else {
            throw new Error('Không thể hủy đơn hàng');
        }
    } catch (error) {
        console.error('Lỗi khi hủy đơn hàng GHTK:', error);
        return {
            success: false,
            message: 'Không thể hủy đơn hàng',
            error: error
        };
    }
};

// Thêm hàm mới để lấy danh sách địa chỉ kho
export const getPickupAddresses = async () => {
    try {
        const response = await axios.get(
            `${GHTK_API.BASE_URL}shipment/list_pick_add`,
            {
                headers: {
                    'Token': GHTK_API.TOKEN,
                    'Content-Type': 'application/json'
                }
            }
            
        );

        if (response.data.success) {
            return {
                success: true,
                data: response.data.data.map(address => ({
                    value: `${address.address}, ${address.ward}, ${address.district}, ${address.province}`,
                    label: `${address.address}, ${address.ward}, ${address.district}, ${address.province}`,
                    pick_name: address.pick_name,
                    pick_tel: address.pick_tel,
                    address: address.address,
                    ward: address.ward,
                    district: address.district,
                    province: address.province
                }))
            };
        } else {
            throw new Error(response.data.message || 'Không thể lấy danh sách địa chỉ kho');
        }
    } catch (error) {
        console.error('Lỗi khi lấy danh sách địa chỉ kho:', error);
        return {
            success: false,
            message: error.message || 'Không thể lấy danh sách địa chỉ kho',
            error: error
        };
    }
};