import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Row, Col, Card, Statistic, Spin } from 'antd';
import TopProducts from '../../components/Analytics/Overvies/TopProducts';
import RecentOrders from '../../components/Analytics/Overvies/RecentOrders';
import { 
  DollarOutlined, 
  ShoppingCartOutlined, 
  ShoppingOutlined,  
} from '@ant-design/icons';

const StatsOverview = () => {
  const navigate = useNavigate();
  const { stats, loading, products, orders } = useOutletContext();

  const formatValue = (value, format) => {
    if (format === 'currency') {
      return new Intl.NumberFormat('vi-VN').format(value);
    }
    return value;
  };

  const getStatValue = (statKey) => {
    const stat = stats?.find(s => s.stat === statKey);
    return stat ? { 
      value: stat.value, 
      format: stat.format,
      reportUrl: stat._links?.report?.[0]?.href,
      apiUrl: stat._links?.api?.[0]?.href
    } : { 
      value: 0, 
      format: 'number',
      reportUrl: '',
      apiUrl: ''
    };
  };

  const statCards = [
    {
      key: 'revenue/total_sales',
      title: 'Tổng doanh số bán hàng',
      icon: <DollarOutlined />,
      suffix: 'đ',
      color: '#3f8600',
      route: '/analytics/revenue'
    },
    {
      key: 'orders/orders_count',
      title: 'Đơn hàng',
      icon: <ShoppingCartOutlined />,
      color: '#1890ff',
      route: '/analytics/orders'
    },
    {
      key: 'products/items_sold',
      title: 'Sản phẩm đã bán',
      icon: <ShoppingOutlined />,
      color: '#722ed1',
      route: '/analytics/products'
    }    
  ];

  if (loading) {
    return <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />;
  }

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
      {statCards.map(card => {
        const { value, format, reportUrl } = getStatValue(card.key);
        return (
          <Col xs={24} sm={12} lg={8}  key={card.key}>
            <Card
              hoverable
              onClick={() => navigate(card.route)}
              style={{ cursor: 'pointer' }}
            >
              <Statistic
                title={card.title}
                value={value}
                formatter={(val) => formatValue(val, format)}
                prefix={card.icon}
                suffix={card.suffix}
                valueStyle={{ 
                  color: card.color,
                }}
              />
            </Card>
          </Col>
        );
      })}
      <Col span={24}>
        <TopProducts products={products} />        
      </Col>
      <Col span={24}>
        <RecentOrders orders={orders} />
      </Col>
    </Row>
  );
};

export default StatsOverview; 