import React from 'react';
import { Input, Select, Space } from 'antd';

const { Search } = Input;
const { Option } = Select;

const InventoryFilters = ({
    searchQuery,    
    stockFilter,
    onSearchChange,
    onSearch,    
    onStockFilterChange,
}) => {
    return (
        <Space style={{ marginBottom: 16 }}>
            <Search
                placeholder="Tìm kiếm sản phẩm"
                value={searchQuery}
                onChange={e => onSearchChange(e.target.value)}
                onSearch={onSearch}
                style={{ width: 300 }}
            />            
            <Select
                value={stockFilter}
                onChange={onStockFilterChange}
                style={{ width: 200 }}
            >
                <Option value="all">Tất cả trạng thái</Option>
                <Option value="instock">Còn hàng</Option>
                <Option value="outofstock">Hết hàng</Option>
                <Option value="onbackorder">Đặt trước</Option>
            </Select>
        </Space>
    );
};

export default InventoryFilters; 