import React, { useEffect, useState } from 'react';
import { Alert,message } from 'antd';
import { fetchOrders, updateOrderStatus, deleteOrder, updatePaymentStatus, updateVatStatus } from '../api';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import OrderFilters from '../components/Orders/OrderFilters';
import OrderTable from '../components/Orders/OrderTable';
import OrderModals from '../components/Orders/OrderModals';
import OrderDetail from '../components/Orders/OrderDetail';
import PageHeader from '../components/Common/PageHeader';
import { orderStatuses, statusColors, paymentMethods, shippingMethods, paymentStatuses } from '../constants/orders';
import ExportExcel from '../components/Common/ExportExcel';
import { formatDate, formatCurrency } from '../utils/format';
import GHTKOrderModal from '../components/Orders/ModalGHTK/GHTKOrderModal';
import { createGHTKOrder } from '../api/ghtkService';
import { STATE_VN } from '../constants/stateVN';
const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: (total, range) => `${range[0]}-${range[1]} của ${total} mục`
        },
    });
    const [statusFilter, setStatusFilter] = useState('any');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState('any');
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dateFilter, setDateFilter] = useState('all');
    const [monthOptions, setMonthOptions] = useState([]);
    const [confirmModal, setConfirmModal] = useState({
        visible: false,
        orderId: null,
        newStatus: null,
        currentStatus: null
    });
    const [deleteModal, setDeleteModal] = useState({
        visible: false,
        orderId: null
    });
    const [paymentConfirmModal, setPaymentConfirmModal] = useState({
        visible: false,
        orderId: null,
        newStatus: null,
        currentStatus: null
    });
    const [ghtkModal, setGhtkModal] = useState({
        visible: false,
        orderData: null
    });
    const [bankCodeToName, setBankCodeToName] = useState({});
    useEffect(() => {
        fetchOrdersData();
        updateMonthOptions();
    }, [JSON.stringify(tableParams), statusFilter, dateFilter, paymentStatusFilter]);
    useEffect(() => {
        const fetchBankData = async () => {
            try {                
                const cachedData = localStorage.getItem('bank_data');
                if (cachedData) {                 
                    const mapping = JSON.parse(cachedData);
                    setBankCodeToName(mapping);
                    return;
                }                    
                const response = await fetch('https://api.vietqr.io/v2/banks');
                const data = await response.json();                                
                const mapping = {};
                data.data.forEach(bank => {
                    mapping[bank.code] = {
                        shortName: bank.shortName,
                        logo: bank.logo,
                        name: bank.name
                    };
                });                                
                localStorage.setItem('bank_data', JSON.stringify(mapping));                                
                setBankCodeToName(mapping);
            } catch (error) {
                console.error('Error fetching bank data:', error);                                
                const cachedData = localStorage.getItem('bank_data');
                if (cachedData) {
                    setBankCodeToName(JSON.parse(cachedData));
                }
            }
        };
    
        fetchBankData();
    }, []);
    const updateMonthOptions = () => {
        const currentMonth = dayjs().month();
        const options = [];
        for (let i = 0; i < 4; i++) {
            const month = dayjs().subtract(i, 'month');
            options.push({
                value: month.format('YYYY-MM'),
                label: month.format('MMMM YYYY')
            });
        }
        setMonthOptions(options);
    };

    const fetchOrdersData = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const [startDate, endDate] = dateFilter.split(' - ');

         // Lấy dữ liệu địa chỉ từ localStorage hoặc fetch từ API
    let addressData;
    try {
        const cachedData = localStorage.getItem('vn_address_data');
        if (cachedData) {
            addressData = JSON.parse(cachedData);
        } else {
            const addressResponse = await fetch('https://lotilife.com/wp-content/plugins/devvn-woo-ghtk/vietnam-checkout/cities/devvn-vn-address.json');
            addressData = await addressResponse.json();
            localStorage.setItem('vn_address_data', JSON.stringify(addressData));
        }
    } catch (error) {
        console.error('Lỗi khi tải dữ liệu địa chỉ:', error);
        addressData = {};
    }

        const params = {
            page: tableParams.pagination.current,
            per_page: tableParams.pagination.pageSize,
            status: statusFilter !== 'any' ? statusFilter : undefined,
            search: searchQuery,
            start_date: startDate,
            end_date: endDate,
            is_paid: paymentStatusFilter !== 'any' ? paymentStatusFilter : undefined,
        };

        try {
            const { 
                data, 
                total, 
                totalPages,
                perPage,
                currentPage 
            } = await fetchOrders(token, params);

            const transformedOrders = data.map(order => {
                const isVatExempt = order.order_details?.meta_data?.find(meta => meta.key === 'is_vat_exempt')?.value === 'yes';
                const stateCode = order.order_details?.billing?.state; // tỉnh thành phố
                const cityCode = order.order_details?.billing?.city; // quận huyện
                const districtCode = order.order_details?.billing?.address_2; // Phường/Xã
                
                let cityName, districtName, wardName, stateName;
                try {
                    stateName = STATE_VN[stateCode] || stateCode;
                    cityName = addressData[stateCode]?.[cityCode]?.name || cityCode;
                    districtName = addressData[stateCode]?.[cityCode]?.wards?.[districtCode]?.name || districtCode;

                } catch (error) {
                    console.error('Lỗi khi mapping địa chỉ:', error);
                }
                const shippingMethod = order.order_details?.shipping_lines?.[0]?.method_id || '';
                const isLocalPickup = shippingMethod.includes('local_pickup') || 
                                     order.order_details?.shipping_method_id?.includes('local_pickup');
                                
                return {
                    id: order.id,
                    number: order.id,
                    status: order.status,
                    is_vat_exempt: isVatExempt ? "yes" : "no",
                    date_created: order.date_created,
                    customer: {
                        id: order.user_id,
                        first_name: order.customer_name.split(' ').slice(0, -1).join(' '),
                        last_name: order.customer_name.split(' ').slice(-1)[0],
                    },
                    billing: {
                        first_name: order.customer_name.split(' ').slice(0, -1).join(' '),
                        last_name: order.customer_name.split(' ').slice(-1)[0],
                        phone: order.customer_phone,
                        address_1: order.order_details?.billing?.address_1,
                        address_2: districtName,
                        city: cityName, 
                        state: stateName,                        
                    },
                    total: order.total,
                    shipping_total: order.shipping_total,
                    order_total: order.order_total,
                    payment_method: order.order_details?.payment_method,
                    payment_method_title: order.order_details?.payment_method_title,
                    shipping_lines: order.order_details?.shipping_lines,
                    shipping_method: shippingMethod,
                    is_local_pickup: isLocalPickup,
                    line_items: order.order_details?.line_items,
                    commission: order.commission,
                    is_paid: order.is_paid,
                    level: order.level,
                    pv_points: order.pv_points,
                    payment_date: order.payment_date,
                    description: order.description,
                    user_detail: order.user_detail,
                };
            });

            setOrders(transformedOrders);
            setTotalOrders(total);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total,
                    current: currentPage,
                    pageSize: perPage,
                },
            });
        } catch (error) {
            console.error('Error fetching orders:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };    
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };
    const handleStatusFilterChange = (value) => {
        setStatusFilter(value);
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
    };

    const handleSearchChange = (value) => {
        setSearchQuery(value);
    };

    const handleSearch = () => {
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
        fetchOrdersData();
    };

    const handleDateFilterChange = (value) => {
        setDateFilter(value);
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
    };
    const handleDateRangeChange = (dates) => {        
        if (dates) {
            const [startDate, endDate] = dates;      
            const formatStart = startDate.format('YYYY-MM-DD');
            const formatEnd = endDate.format('YYYY-MM-DD');
            setDateFilter(formatStart + ' - ' + formatEnd);
        }
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));        
    };

    const handlePaymentStatusFilterChange = (value) => {      
        setPaymentStatusFilter(value);
        setTableParams(prev => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current: 1,
            },
        }));
    };

    const showOrderDetail = (orderId) => {
        setSelectedOrderId(orderId);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedOrderId(null);
    };

    const showStatusConfirm = (orderId, newStatus, currentStatus) => {
        setConfirmModal({
            visible: true,
            orderId,
            newStatus,
            currentStatus
        });
    };

    const handleStatusChange = async (orderId, newStatus) => {
        showStatusConfirm(orderId, newStatus, orders.find(o => o.id === orderId)?.status);
    };


    const handleConfirmStatusChange = async () => {
        const { orderId, newStatus } = confirmModal;
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            await updateOrderStatus(token, orderId, newStatus);
            message.success('Cập nhật trạng thái thành công');
            fetchOrdersData();
        } catch (error) {
            console.error('Error updating order status:', error);
            message.error('Không thể cập nhật trạng thái đơn hàng');
        } finally {
            setLoading(false);
            setConfirmModal({ visible: false, orderId: null, newStatus: null, currentStatus: null });
        }
    };

    const handleCreateGHTKOrder = async (orderId) => {
        const order = orders.find(o => o.id === orderId);           
        setGhtkModal({
            visible: true,
            orderData: order
        });
    };

    const handleGHTKSubmit = async (values) => {        
        try {
            setLoading(true);
            const result = await createGHTKOrder({
                ...values,
                orderId: ghtkModal.orderData.id
            });
            
            if (result.success) {
                message.success(result.message);
                setGhtkModal({ visible: false, orderData: null });
                await fetchOrdersData();
            } else {
                message.error(result.message);
            }
        } catch (error) {
            console.error('Lỗi khi tạo đơn GHTK:', error);
            message.error('Không thể tạo đơn GHTK');
        } finally {
            setLoading(false);
        }
    };

    const showDeleteConfirm = (orderId) => {
        setDeleteModal({
            visible: true,
            orderId
        });
    };

    const handleDeleteOrder = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            await deleteOrder(token, deleteModal.orderId);
            message.success('Xóa đơn hàng thành công');
            fetchOrdersData();
        } catch (error) {
            console.error('Error deleting order:', error);
            message.error('Không thể xóa đơn hàng');
        } finally {
            setLoading(false);
            setDeleteModal({ visible: false, orderId: null });
        }
    };

    const handlePaymentStatusChange = async (orderId, newStatus) => {
        showPaymentStatusConfirm(orderId, newStatus, orders.find(o => o.id === orderId)?.is_paid);
    };

    const showPaymentStatusConfirm = (orderId, newStatus, currentStatus) => {
        setPaymentConfirmModal({
            visible: true,
            orderId,
            newStatus,
            currentStatus
        });
    };

    const handleConfirmPaymentChange = async () => {
        const { orderId, newStatus } = paymentConfirmModal;
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            await updatePaymentStatus(token, orderId, newStatus);
            message.success('Cập nhật trạng thái thanh toán thành công');
            fetchOrdersData();
        } catch (error) {
            console.error('Error updating payment status:', error);
            message.error('Không thể cập nhật trạng thái thanh toán');
        } finally {
            setLoading(false);
            setPaymentConfirmModal({ visible: false, orderId: null, newStatus: null, currentStatus: null });
        }
    };
    const handleVatChange = async (orderId, newValue) => {
        try {            
            
            setLoading(true);
            const token = localStorage.getItem('token');
            await updateVatStatus(token, orderId, newValue);                        
            setOrders(prevOrders => prevOrders.map(order => {
                if (order.id === orderId) {
                    return {
                        ...order,
                        is_vat_exempt: newValue 
                    };
                }
                return order;
            }));
            message.success('Cập nhật trạng thái VAT thành công');
        } catch (error) {
            console.error('Error updating VAT status:', error);
            message.error('Không thể cập nhật trạng thái VAT');
        } finally {
            setLoading(false);
        }
    };
    const exportColumns = [
        {
            title: 'Mã đơn hàng',
            dataIndex: 'id',
            required: true,
            key: 'id'
        },
        {
            title: 'Ngày đặt hàng',
            dataIndex: 'date_created',
            exportRender: (value) => formatDate(value),
            key: 'date_created'
        },
        {
            title: 'Mã NPP',
            dataIndex: ['user_detail', 'custom_user_id'],
            exportRender: (_, record) => record.user_detail?.custom_user_id || '',
            key: 'custom_user_id'
        },
        {
            title: 'Tên NPP',
            dataIndex: ['user_detail', 'user_display_name'],
            exportRender: (_, record) => record.user_detail?.user_display_name || '',
            key: 'user_display_name'
        },    
        {
            title: 'CCCD',
            dataIndex: ['user_detail', 'id_number'],
            exportRender: (_, record) => record.user_detail?.id_number || '',
            key: 'id_number'
        },
        {
            title: 'Ngày cấp CCCD',
            dataIndex: ['user_detail', 'cccd_issue_date'],            
            key: 'cccd_issue_date'
        },
        {
            title: 'Nơi cấp CCCD',
            dataIndex: ['user_detail', 'cccd_issue_place'],
            exportRender: (_, record) => record.user_detail?.cccd_issue_place || '',
            key: 'cccd_issue_place'
        },
        {
            title:"Tên ngân hàng",
            dataIndex: ['user_detail', 'bank_name'],
            exportRender: (_, record) => {
                const bankCode = record.user_detail?.bank_name;
                return bankCodeToName[bankCode]?.shortName || bankCode || '';
            },
            key: 'bank_name'
        },
        {
            title:"Số tài khoản ngân hàng",
            dataIndex: ['user_detail', 'bank_account_number'],
            exportRender: (_, record) => record.user_detail?.bank_account_number || '',
            key: 'bank_account_number'
        },
        {
            title:"Tên chủ tài khoản",
            dataIndex: ['user_detail', 'bank_account_name'],
            exportRender: (_, record) => record.user_detail?.bank_account_name || '',
            key: 'bank_account_name'
        },
        {
            title: 'Địa chỉ thường trú',
            dataIndex: ['user_detail', 'address'],
            exportRender: (_, record) => record.user_detail?.address || '',
            key: 'address'
        },
        {
            title: 'Địa chỉ tạm trú',
            dataIndex: ['user_detail', 'temporary_address'],
            exportRender: (_, record) => record.user_detail?.temporary_address || '',
            key: 'temporary_address'
        },
        {
            title: 'Người nhận',
            dataIndex: ['customer', 'first_name'],
            exportRender: (_, record) => record.customer_name || `${record.customer?.first_name || ''} ${record.customer?.last_name || ''}`,
            key: 'customer'
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'line_items',
            exportRender: (line_items) => line_items?.map(item => item.name).join(', ') || '',
            key: 'product_name'
        },
        {
            title: 'Số lượng',
            dataIndex: 'line_items',
            exportRender: (line_items) => line_items?.reduce((sum, item) => sum + item.quantity, 0) || 0,
            key: 'quantity'
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total',
            exportRender: (value) => (value),
            key: 'total'
        },
        {
            title: 'Trạng thái đơn hàng',
            dataIndex: 'status',
            exportRender: (value) => orderStatuses.find(status => status.value === value)?.label || value,
            key: 'status'
        },
        {
            title: 'Trạng thái thanh toán',
            dataIndex: 'is_paid',
            exportRender: (value) => paymentStatuses.find(status => status.value === value)?.label || value,
            key: 'payment_status'
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'payment_method_title',
            key: 'payment_method',
            exportRender: (value) => paymentMethods.find(method => method.value === value)?.label || value
        },
        {
            title: 'Hình thức giao hàng',
            dataIndex: 'shipping_method',
            key: 'shipping_method',
            exportRender: (value) => shippingMethods.find(method => method.value === value)?.label || 'Không xác định'
        },
        {
            title: 'VAT',
            dataIndex: 'is_vat_exempt',
            exportRender: (value) => value === "yes" ? "Đã xuất VAT" : "Chưa xuất VAT",
            key: 'is_vat_exempt'
        }
    ];
    const handleExportData = async (exportParams) => {
        const token = localStorage.getItem('token');              
        const params = {
            page: 1,
            per_page: 999999,
            status: statusFilter !== 'any' ? statusFilter : undefined,
            search: searchQuery,
            is_paid: paymentStatusFilter !== 'any' ? paymentStatusFilter : undefined,            
            ...exportParams
        };
        try {
            const response = await fetchOrders(token, params);                      
            const transformedOrders = response.data.map(order => {
                const isVatExempt = order.order_details?.meta_data?.find(
                    meta => meta.key === 'is_vat_exempt'
                )?.value === 'yes';
                const shippingMethod = order.order_details?.shipping_lines?.[0]?.method_id || '';
                const isLocalPickup = shippingMethod.includes('local_pickup') || 
                                     order.order_details?.shipping_method_id?.includes('local_pickup');
                return {
                    id: order.id,
                    number: order.id,
                    status: order.status,
                    date_created: order.date_created,
                    customer: {
                        id: order.user_id,
                        first_name: order.customer_name.split(' ').slice(0, -1).join(' '),
                        last_name: order.customer_name.split(' ').slice(-1)[0],
                    },
                    billing: {
                        first_name: order.customer_name.split(' ').slice(0, -1).join(' '),
                        last_name: order.customer_name.split(' ').slice(-1)[0],
                        phone: order.customer_phone,
                    },
                    total: order.total,
                    shipping_total: order.shipping_total,
                    order_total: order.order_total,
                    payment_method: order.order_details?.payment_method,
                    payment_method_title: order.order_details?.payment_method_title,
                    shipping_lines: order.order_details?.shipping_lines,
                    shipping_method: shippingMethod,
                    is_local_pickup: isLocalPickup,
                    line_items: order.order_details?.line_items,
                    commission: order.commission,
                    is_paid: order.is_paid,
                    level: order.level,
                    pv_points: order.pv_points,
                    payment_date: order.payment_date,
                    description: order.description,
                    user_detail: order.user_detail,
                    is_vat_exempt: isVatExempt ? "yes" : "no"
                };
            });

            return transformedOrders;
        } catch (error) {
            console.error('Error fetching export data:', error);
            message.error('Không thể tải dữ liệu xuất Excel');
            throw error;
        }
    };

    return (
        <div>
            <PageHeader 
                title="Quản lý đơn hàng"
                extra={[
                    <ExportExcel
                    fetchData={handleExportData}
                    columns={exportColumns}                   
                />]} 
            />            
            {error && <Alert message="Lỗi" description={error} type="error" />}
            
            <OrderFilters 
                searchQuery={searchQuery}
                statusFilter={statusFilter}
                dateFilter={dateFilter}
                loading={loading}
                monthOptions={monthOptions}
                orderStatuses={orderStatuses}
                paymentStatuses={paymentStatuses}
                onSearchChange={handleSearchChange}
                onSearch={handleSearch}
                onStatusFilterChange={handleStatusFilterChange}
                onPaymentStatusFilterChange={handlePaymentStatusFilterChange}
                onDateFilterChange={handleDateFilterChange}
                onDateRangeChange={handleDateRangeChange}
            />
            
            <OrderTable 
                orders={orders}
                loading={loading}
                tableParams={tableParams}
                orderStatuses={orderStatuses}
                statusColors={statusColors}
                paymentMethods={paymentMethods}
                paymentStatuses={paymentStatuses}
                shippingMethods={shippingMethods}
                onTableChange={handleTableChange}
                onStatusChange={handleStatusChange}
                onPaymentStatusChange={handlePaymentStatusChange}
                onShowDetail={showOrderDetail}
                onCreateGHTK={handleCreateGHTKOrder}
                onDelete={showDeleteConfirm}
                onVatChange={handleVatChange}
            />
            
            <OrderModals 
                confirmModal={confirmModal}
                deleteModal={deleteModal}
                paymentConfirmModal={paymentConfirmModal}
                statusColors={statusColors}
                orderStatuses={orderStatuses}
                paymentStatuses={paymentStatuses}
                loading={loading}
                onConfirmStatus={handleConfirmStatusChange}
                onCancelStatus={() => setConfirmModal({ visible: false, orderId: null, newStatus: null, currentStatus: null })}
                onConfirmDelete={handleDeleteOrder}
                onCancelDelete={() => setDeleteModal({ visible: false, orderId: null })}
                onConfirmPayment={handleConfirmPaymentChange}
                onCancelPayment={() => setPaymentConfirmModal({ visible: false, orderId: null, newStatus: null, currentStatus: null })}
            />
            
            <OrderDetail
                orderId={selectedOrderId}
                visible={isModalVisible}
                onClose={handleModalClose}
            />
            
            <GHTKOrderModal
                visible={ghtkModal.visible}
                orderData={ghtkModal.orderData}
                onCancel={() => setGhtkModal({ visible: false, orderData: null })}
                onSubmit={handleGHTKSubmit}
                loading={loading}
            />
        </div>
    );
};

export default OrderList;