import React, { useState } from 'react';
import { Table, Select, Button, Space, Icon, Tooltip, Badge, Spin, message,Checkbox } from 'antd';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { formatCurrency, formatDate } from '../../utils/format';
import UserDetailsModal from '../Orders/UserDetailModal';
import styled from 'styled-components';
const { Option } = Select;
const StyledTable = styled(Table)`
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 4px 8px !important;
    }
`;

const OrderTable = ({ 
    orders,
    loading,
    tableParams,
    orderStatuses,
    statusColors,
    onTableChange,
    onStatusChange,
    onShowDetail,
    onCreateGHTK,
    onDelete,
    paymentMethods,
    paymentStatuses,
    shippingMethods,
    onPaymentStatusChange,
    onVatChange
}) => {
    const [isUserModalVisible, setIsUserModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);

    const showUserModal = (record) => {        
        setSelectedUser(record);
        setIsUserModalVisible(true);
    };

    const expandedRowRender = (record) => {        
        const lineItems = record?.line_items || [];        
        const expandedColumns = [
            {
                title: 'Tên sản phẩm',
                dataIndex: 'name',
                key: 'name',
                width: '40%',
            },
            {
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                width: 120,
            },
            {
                title: 'Số lượng',
                dataIndex: 'quantity',
                key: 'quantity',
                width: 100,
                align: 'center',
            },
            {
                title: 'Đơn giá',
                dataIndex: 'price',
                key: 'price',
                width: 120,
                align: 'right',
                render: (price) => formatCurrency(price)
            },
            {
                title: 'Thành tiền',
                key: 'total',
                width: 120,
                align: 'right',
                render: (_, record) => formatCurrency(record.total)
            }
        ];

        return (
            <Table 
                columns={expandedColumns}
                dataSource={lineItems}
                pagination={false}
                rowKey="id"
                size="small"
                bordered                
            />
        );
    };

    const columns = [
        {
            title: 'STT',
            key: 'index',
            width: 50,
            render: (_, __, index) => {
                const { current, pageSize } = tableParams.pagination;
                return ((current - 1) * pageSize) + index + 1;
            },
        },
        {
            title: 'Mã đơn hàng',
            dataIndex: 'id',
            key: 'id',
            width: 70,
        },
        {
            title: 'Ngày đặt hàng',
            dataIndex: 'date_created',
            key: 'date_created',
            width: 120,
            render: (date) => formatDate(date)
        },
        {
            title: 'Mã NPP',
            dataIndex: ['user_detail', 'custom_user_id'],
            key: 'custom_user_id',
            width: 80,
            render: (custom_user_id,record) => {
              return <a onClick={() => showUserModal(record)}>{custom_user_id}</a>
            }
        },
        {
            title: 'Tên NPP',
            dataIndex: ['user_detail', 'user_display_name'],
            key: 'user_display_name',
            width: 150,
            render: (user_display_name,record) => {
                return <a onClick={() => showUserModal(record)}>{user_display_name}</a>
            }
        },
        {
            title: 'Người nhận',
            dataIndex: ['billing', 'first_name'],
            key: 'customer_name',
            width: 150,
            render: (text, record) => (
                `${record.customer.first_name} ${record.customer.last_name}`
            ),
        },
        {
            title: 'Tổng tiền',
            dataIndex: 'total',
            key: 'total',
            width: 100,
            render: (text) => formatCurrency(text)
        },
        {
            title: 'Trạng thái đơn hàng',
            dataIndex: 'status',
            key: 'status',
            width: 160,
            render: (status, record) => {
                const isDisabled = record.is_paid === "0" || loading || record.status === 'cancelled';
                const tooltipTitle = record.is_paid === "0" && record.status !== 'cancelled' ? "Vui lòng xác nhận thanh toán trước khi thay đổi trạng thái đơn hàng" : "";

                return (
                    <Tooltip title={tooltipTitle}>
                        <Select
                            value={status}
                            style={{ width: '100%' }}
                            onChange={(value) => onStatusChange(record.id, value)}
                            disabled={isDisabled}
                        >
                            {orderStatuses.map(status => (
                                <Select.Option 
                                    key={status.value} 
                                    value={status.value}
                                >
                                    <Badge 
                                        color={statusColors[status.value]} 
                                        text={status.label} 
                                    />
                                </Select.Option>
                            ))}
                        </Select>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Trạng thái thanh toán',
            dataIndex: 'is_paid',
            key: 'is_paid',
            width: 110,
            render: (is_paid, record) => {
                if (record.status === 'cancelled') {
                    return '-';
                }

                return (
                    <Select
                        value={is_paid}
                        style={{ width: '100%' }}
                        onChange={(value) => onPaymentStatusChange(record.id, value)}
                        disabled={loading}
                    >
                        {paymentStatuses.map(status => (
                            <Select.Option key={status.value} value={status.value}>
                                {status.label}
                            </Select.Option>
                        ))}
                    </Select>
                );
            }
        },
        {
            title: 'Hình thức thanh toán',
            dataIndex: 'payment_method',
            key: 'payment_method',
            width: 110,
            render: (methodId) => paymentMethods.find(method => method.value === methodId)?.label || '-'
        },
        {
            title: 'Hình thức giao hàng',
            dataIndex: ['shipping_lines', 0, 'method_id'],
            key: 'method_id',
            width: 100,
            render: (methodId) => shippingMethods.find(method => method.value === methodId)?.label || 'Không xác định'
        },
        {
            title: 'VAT',
            dataIndex: 'is_vat_exempt',
            key: 'is_vat_exempt',
            width: 50,
            align: 'center',
            render: (is_vat_exempt, record) => {
                const isDisabled = record.status !== 'completed' || loading;
                
                return (
                    <Tooltip title={record.status !== 'completed' ? "Chỉ được xuất VAT khi đơn hàng hoàn thành" : ""}>
                        <Checkbox
                            checked={is_vat_exempt === "yes"}
                            onChange={() => {
                                const newValue = is_vat_exempt !== "yes";                                
                                onVatChange(record.id, newValue ? "yes" : "no");
                            }}
                            disabled={isDisabled}
                        />
                    </Tooltip>
                );
            }
        },
        {
            title: 'Hành động',
            key: 'action',
            fixed: 'right',
            width: 200,
            render: (_, record) => (
                <Space size="small">
                    <Button icon={<EyeOutlined />} onClick={() => onShowDetail(record.id)}>                        
                    </Button>
                    <Button 
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onCreateGHTK(record.id)}
                        disabled={record.status === 'completed' || record.status === 'cancelled'||record.status_payment === 'unpaid'||record.is_local_pickup}
                    >
                    GHTK
                    </Button>
                    {/* <Button 
                        type="primary" 
                        danger
                        onClick={() => onDelete(record.id)}
                    >
                        Xóa
                    </Button> */}
                </Space>
            ),
        },
    ];

    return (
        <>
            <StyledTable 
                dataSource={orders}
                columns={columns} 
                rowKey="id" 
                pagination={tableParams.pagination}
                loading={loading}
                onChange={onTableChange}
                scroll={{ x: 1500 }}
                expandable={{
                    expandedRowRender,
                    expandRowByClick: false,
                }}
                bordered
                size="small"   
                scroll={{ x: 1200,y: 600 }}
                sticky    
                
                
            />
            
            <UserDetailsModal
                visible={isUserModalVisible}
                user={selectedUser}
                onClose={() => setIsUserModalVisible(false)}
            />
        </>
    );
};

export default OrderTable; 