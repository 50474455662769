import React, { useState } from 'react';
import { Button, Modal, Checkbox, Space, Row, Col, Divider, message, Radio } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExportMembers = ({ fetchData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('all');
    const [selectedColumns, setSelectedColumns] = useState([
        'ID', 'user_email', 'display_name', 'user_registered', 
        'custom_user_id', 'user_phone', 'account_status'
    ]);

    const columnGroups = [
        {
            title: 'Thông tin cơ bản',
            items: [
                { label: 'ID', value: 'ID' },
                { label: 'Tên đăng nhập', value: 'user_login' },
                { label: 'Email', value: 'user_email' },
                { label: 'Họ tên', value: 'display_name' },
                { label: 'Ngày đăng ký', value: 'user_registered' },
                { label: 'Mã NPP', value: 'custom_user_id' },
                { label: 'Số điện thoại', value: 'user_phone' },
                { label: 'Trạng thái', value: 'account_status' },
            ]
        },
        // {
        //     title: 'Thông tin tài chính',
        //     items: [
        //         { label: 'Điểm', value: 'points' },
        //         { label: 'Hoa hồng PV', value: 'commission_pv' },
        //         { label: 'Số dư', value: 'balance' },
        //         { label: 'Số dư tạm', value: 'temp_balance' },
        //         { label: 'Doanh số', value: 'sales' },
        //     ]
        // },
        {
            title: 'Thông tin cá nhân',
            items: [
                { label: 'Ngày sinh', value: 'dob' },
                { label: 'CCCD/CMND', value: 'id_number' },
                { label: 'Ngày cấp', value: 'cccd_issue_date' },
                { label: 'Nơi cấp', value: 'cccd_issue_place' },
                { label: 'Nghề nghiệp', value: 'occupation' },
            ]
        },
        {
            title: 'Địa chỉ',
            items: [
                { label: 'Địa chỉ thường trú', value: 'permanent_address' },
                { label: 'Địa chỉ tạm trú', value: 'temporary_address' },
            ]
        },
        {
            title: 'Thông tin ngân hàng',
            items: [
                { label: 'Tên ngân hàng', value: 'bank_name' },
                { label: 'Chi nhánh', value: 'bank_branch' },
                { label: 'Số tài khoản', value: 'bank_account_number' },
                { label: 'Tên tài khoản', value: 'bank_account_name' },
            ]
        },
        {
            title: 'Thông tin cấp trên',
            items: [
                { label: 'ID cấp trên', value: 'parent_id' },
                { label: 'Tên cấp trên', value: 'parent_name' },
            ]
        }
    ];

    const handleExport = () => {
        if (selectedColumns.length === 0) {
            message.warning('Vui lòng chọn ít nhất một cột để xuất');
            return;
        }
        fetchData(selectedType, selectedColumns);
        setIsModalOpen(false);
    };

    const handleSelectAll = (groupItems) => {
        const values = groupItems.map(item => item.value);
        const allSelected = values.every(value => selectedColumns.includes(value));
        
        if (allSelected) {
            setSelectedColumns(prev => prev.filter(col => !values.includes(col)));
        } else {
            setSelectedColumns(prev => [...new Set([...prev, ...values])]);
        }
    };

    return (
        <>
            <Button 
                type="primary" 
                icon={<DownloadOutlined />} 
                onClick={() => setIsModalOpen(true)}
            >
                Xuất Excel
            </Button>

            <Modal
                title={
                    <Space>
                        <DownloadOutlined />
                        <span>Xuất danh sách thành viên</span>
                    </Space>
                }
                open={isModalOpen}
                onOk={handleExport}
                onCancel={() => setIsModalOpen(false)}
                width={800}
                okText="Xuất"
                cancelText="Hủy"
            >
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                    {/* Chọn loại xuất */}
                    <div>
                        <div style={{ marginBottom: 8, fontWeight: 500 }}>Chọn loại thành viên:</div>
                        <Radio.Group 
                            value={selectedType}
                            onChange={(e) => setSelectedType(e.target.value)}
                            style={{ width: '100%' }}
                        >
                            <Row>
                                <Col span={12}>
                                    <Radio value="all">Toàn bộ hệ thống</Radio>
                                </Col>
                                <Col span={12}>
                                    <Radio value="f0">Thành viên F0</Radio>
                                </Col>
                            </Row>
                        </Radio.Group>
                    </div>

                    <Divider />

                    {/* Chọn cột xuất */}
                    <div>
                        <div style={{ marginBottom: 16, fontWeight: 500 }}>Chọn các cột muốn xuất:</div>
                        <Checkbox.Group
                            value={selectedColumns}
                            onChange={setSelectedColumns}
                            style={{ width: '100%' }}
                        >
                            <Space direction="vertical" style={{ width: '100%' }} size="middle">
                                {columnGroups.map((group, index) => (
                                    <div key={index}>
                                        <div 
                                            style={{ 
                                                marginBottom: 8,
                                                cursor: 'pointer',
                                                color: '#1890ff'
                                            }}
                                            onClick={() => handleSelectAll(group.items)}
                                        >
                                            {group.title}
                                        </div>
                                        <Row gutter={[16, 8]}>
                                            {group.items.map(item => (
                                                <Col span={8} key={item.value}>
                                                    <Checkbox value={item.value}>
                                                        {item.label}
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ))}
                            </Space>
                        </Checkbox.Group>
                    </div>
                </Space>
            </Modal>
        </>
    );
};

export default ExportMembers; 