import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Table, Tag, Spin, Card, Row, Col, Typography, Divider, Space } from 'antd';
import { fetchOrderDetail } from '../../api';
import dayjs from 'dayjs';
import { orderStatuses, statusColors, paymentMethods, shippingMethods } from '../../constants/orders';

const { Title, Text } = Typography;

const OrderDetail = ({ orderId, visible, onClose }) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (orderId && visible) {
      fetchOrderData();
    }
  }, [orderId, visible]);

  const fetchOrderData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const data = await fetchOrderDetail(token, orderId);
      setOrderDetail(data);
    } catch (error) {
      console.error('Error fetching order detail:', error);
    } finally {
      setLoading(false);
    }
  };

  const statusColors = {
    pending: 'orange',
    processing: 'blue',
    completed: 'green',
    cancelled: 'red',
    'on-hold': 'gold',
    // thêm các trạng thái khác nếu cần
  };

  const columns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'price',
      key: 'price',
      align: 'right',
      render: (text) => `${parseFloat(text).toLocaleString('vi-VN')}đ`,
    },    
    {
      title: 'Tổng',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: (text) => `${parseFloat(text).toLocaleString('vi-VN')}đ`,
    },
  ];

  if (!orderDetail) {
    return null;
  }

  return (
    <Modal
      title={
        <Space>
          <Title level={4} style={{ margin: 0 }}>Đơn hàng #{orderId}</Title>
          {orderDetail && (
            <Tag color={statusColors[orderDetail.status]}>
              {orderStatuses.find(status => status.value === orderDetail.status)?.label || orderDetail.status.toUpperCase()}
            </Tag>
          )}
        </Space>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
      style={{ top: 20 }}
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '30px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ maxHeight: 'calc(100vh - 200px)' }}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Card title="Thông tin giao hàng" size="small">
                <Descriptions column={1} size="small">
                  
                  <Descriptions.Item label="Họ tên">
                    {`${orderDetail.billing.first_name} ${orderDetail.billing.last_name}`}
                  </Descriptions.Item>                  
                  <Descriptions.Item label="Số điện thoại">{orderDetail.billing.phone}</Descriptions.Item>
                  <Descriptions.Item label="Địa chỉ">
                    {orderDetail.shipping_lines[0].method_id ==='local_pickup' ? "" : `${orderDetail.billing.address_1}, ${orderDetail.billing.address_2}, ${orderDetail.billing.city}, ${orderDetail.billing.state}`}
                  </Descriptions.Item>                  
                  <Descriptions.Item label="Phương thức thanh toán">
                    <Text strong>{orderDetail.payment_method_title}</Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Phương thức giao hàng">
                    <Text strong>{orderDetail.shipping_lines[0]?.method_title || 'N/A'}</Text>
                  </Descriptions.Item>  
                  <Descriptions.Item label="Phí giao hàng">
                    <Text strong>{ orderDetail.shipping_lines[0]?.total ? `${parseFloat(orderDetail.shipping_lines[0].total).toLocaleString('vi-VN')}đ` : 'N/A'}</Text>
                  </Descriptions.Item>                
                </Descriptions>
                
              </Card>
            </Col>                        
          </Row>

          <Card title="Chi tiết đơn hàng" size="small" style={{ marginTop: 24 }}>
            <Table
              columns={columns}
              dataSource={orderDetail.line_items}
              pagination={false}
              rowKey="id"
              size="small"
            />
            <Divider style={{ margin: '12px 0' }} />
            <Row justify="end">
              <Col span={8}>
                <Descriptions column={1} size="small">
                  <Descriptions.Item label={<Text strong>Tổng cộng</Text>}>
                    <Text strong type="danger" style={{ fontSize: '16px' }}>
                      {`${parseFloat(orderDetail.total).toLocaleString('vi-VN')}đ`}
                    </Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Ngày đặt hàng">
                    {dayjs(orderDetail.date_created).format('DD/MM/YYYY HH:mm:ss')}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </Modal>
  );
};

export default OrderDetail;