import React from 'react';
import { Row, Col, Card, Table } from 'antd';


const RecentOrders = ({ orders }) => {
  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'id',
      render: (id) => `#${id}`,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'billing',
      render: (billing) => `${billing.first_name} ${billing.last_name}`,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <span style={{ 
          color: status === 'completed' ? '#52c41a' : 
                 status === 'processing' ? '#1890ff' : '#f5222d' 
        }}>
          {status === 'completed' ? 'Hoàn thành' : 
           status === 'processing' ? 'Đang xử lý' : 'Đã hủy'}
        </span>
      ),
    },
    {
      title: 'Giá trị',
      dataIndex: 'total',
      render: (value) => `${parseFloat(value).toLocaleString()}đ`,
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'date_created',
      render: (date) => new Date(date).toLocaleDateString('vi-VN'),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
      <Col span={24}>
        <Card title="Đơn hàng gần đây">
          <Table
            columns={columns}
            dataSource={orders}            
            rowKey="id"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default RecentOrders; 