import axios from 'axios';
import dayjs from 'dayjs';

const host = 'https://lotilife.com/wp-json';

export const getTotalRevenue = async (token, { startDate, endDate }) => {
  try {
    const params = {
      order: 'asc',
      interval: 'day',
      per_page: 100,
      after: dayjs(startDate).startOf('day').toISOString(),
      before: dayjs(endDate).endOf('day').toISOString(),
      fields: [
        'gross_sales',
        'refunds',
        'coupons',
        'net_revenue',
        'taxes',
        'shipping',
        'total_sales'
      ],
      _locale: 'user'
    };

    const response = await axios.get(`${host}/wc-analytics/reports/revenue/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return value.map((val, index) => `fields[${index}]=${val}`).join('&');
            }
            return `${key}=${encodeURIComponent(value)}`;
          })
          .join('&');
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPerformanceIndicators = async (token, { startDate, endDate }) => {
  try {
    const params = {
      after: dayjs(startDate).startOf('day').toISOString(),
      before: dayjs(endDate).endOf('day').toISOString(),
      stats: [
        'revenue/total_sales',
        'orders/orders_count',
        'products/items_sold',
        'variations/items_sold'
      ],
      _locale: 'user'
    };

    const response = await axios.get(`${host}/wc-analytics/reports/performance-indicators`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return `${key}=${value.join(',')}`;
            }
            return `${key}=${encodeURIComponent(value)}`;
          })
          .join('&');
      }
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

  // Lấy thống kê sản phẩm bán chạy
  export const getTopProducts = async (token, params) => {
    try {
      const response = await axios.get(`${host}/wc/v3/reports/top_sellers`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching top products:', error);
      throw error;
    }
  };
// Lấy thống kê đơn hàng và doanh thu
export const getOrdersStats = async (token, params) => {
  try {
    const response = await axios.get(`${host}/wc/v3/reports/orders/totals`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching orders stats:', error);
    throw error;
  }
};



// Lấy danh sách đơn hàng
export const getOrders = async (token, params) => {
  try {
    const response = await axios.get(`${host}/wc/v3/orders`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...params,
        per_page: 10,
        orderby: 'date',
        order: 'desc'
      }
    });
    return {
      data: response.data,
      total: parseInt(response.headers['x-wp-total']),
      totalPages: parseInt(response.headers['x-wp-totalpages'])
    };
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

// Lấy danh sách danh mục sản phẩm
export const getCategories = async (token) => {
  try {
    const response = await axios.get(`${host}/wc/v3/products/categories`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};
// Lấy thống kê doanh thu chi tiết
export const getRevenueStats = async (token, params) => {
  try {
    const response = await axios.get(`${host}/wc-analytics/reports/revenue/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching revenue stats:', error);
    throw error;
  }
};

// Lấy thống kê đơn hàng chi tiết
export const getOrderStats = async (token, params) => {
  try {
    const response = await axios.get(`${host}/wc-analytics/reports/orders/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching order stats:', error);
    throw error;
  }
};

// Lấy thống kê sản phẩm chi tiết
export const getProductStats = async (token, params) => {
  try {
    const response = await axios.get(`${host}/wc-analytics/reports/products/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching product stats:', error);
    throw error;
  }
};

// Lấy thống kê biến thể sản phẩm
export const getVariationStats = async (token, params) => {
  try {
    const response = await axios.get(`${host}/wc-analytics/reports/variations/stats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching variation stats:', error);
    throw error;
  }
};
