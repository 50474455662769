/**
 * Format số tiền theo định dạng tiền tệ Việt Nam
 * @param {number|string} amount - Số tiền cần format
 * @param {boolean} showSymbol - Có hiển thị ký hiệu tiền tệ hay không (mặc định: true)
 * @returns {string} Chuỗi đã được format (vd: "1.000.000 ₫")
 */
import dayjs from 'dayjs';
export const formatCurrency = (amount, showSymbol = true) => {
    const formatter = new Intl.NumberFormat('vi-VN', {
        style: showSymbol ? 'currency' : 'decimal',
        currency: 'VND',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    
    return formatter.format(amount);
};

/**
 * Format ngày giờ theo định dạng Việt Nam
 * @param {string|Date} date - Ngày cần format
 * @param {string} format - Định dạng mong muốn (mặc định: DD/MM/YYYY HH:mm:ss)
 * @returns {string} Chuỗi ngày đã được format
 */
export const formatDate = (date, format = 'DD/MM/YYYY HH:mm:ss') => {
    return dayjs(date).format(format);
}; 