export const orderStatuses = [
    // { value: 'pending', label: 'Chờ xử lý' },
    { value: 'processing', label: 'Chờ giao hàng' },
    { value: 'on-hold', label: 'Chờ XNTT' },
    { value: 'completed', label: 'Hoàn thành' },
    { value: 'cancelled', label: 'Đã hủy' },
    // { value: 'refunded', label: 'Hoàn tiền' },
    // { value: 'failed', label: 'Thất bại' }
];

export const statusColors = {
    pending: 'orange',
    processing: 'blue',
    'on-hold': 'gold',
    completed: 'green',
    cancelled: 'red',
    refunded: 'purple',
    failed: 'volcano',
}; 
export const paymentMethods = [
    { value: 'cod', label: 'Tiền mặt' },
    { value: 'vicweb_acb', label: 'Chuyển khoản' },
];
export const paymentStatuses = [
    { value: '0', label: 'Chờ TT' },
    { value: '1', label: 'Đã TT' },
    { value: '2', label: 'Đã hủy' },
];
export const shippingMethods = [
    { value: 'local_pickup', label: 'Tại cửa hàng' },
    { value: 'ghtk_shipping_method', label: 'GHTK' },
];
