import React from 'react';
import { Modal, Tag, Alert } from 'antd';

const OrderModals = ({
    confirmModal,
    deleteModal,
    paymentConfirmModal,
    statusColors,
    orderStatuses,
    paymentStatuses,
    loading,
    onConfirmStatus,
    onCancelStatus,
    onConfirmDelete,
    onCancelDelete,
    onConfirmPayment,
    onCancelPayment
}) => {
    return (
        <>
            <Modal
                title="Xác nhận thay đổi trạng thái"
                open={confirmModal.visible}
                onOk={onConfirmStatus}
                onCancel={onCancelStatus}
                confirmLoading={loading}
            >
                <p>Bạn có chắc chắn muốn thay đổi trạng thái đơn hàng #{confirmModal.orderId} từ</p>
                <p>
                    <Tag color={statusColors[confirmModal.currentStatus]}>
                        {orderStatuses.find(s => s.value === confirmModal.currentStatus)?.label || confirmModal.currentStatus}
                    </Tag>
                    {' '}sang{' '}
                    <Tag color={statusColors[confirmModal.newStatus]}>
                        {orderStatuses.find(s => s.value === confirmModal.newStatus)?.label || confirmModal.newStatus}
                    </Tag>
                </p>
            </Modal>

            <Modal
                title="Xác nhận xóa đơn hàng"
                open={deleteModal.visible}
                onOk={onConfirmDelete}
                onCancel={onCancelDelete}
                confirmLoading={loading}
            >
                <p>Bạn có chắc chắn muốn xóa đơn hàng #{deleteModal.orderId}?</p>
                <Alert
                    message="Cảnh báo"
                    description="Hành động này không thể hoàn tác. Đơn hàng sẽ bị xóa vĩnh viễn khỏi hệ thống."
                    type="warning"
                    showIcon
                />
            </Modal>

            <Modal
                title="Xác nhận thay đổi trạng thái thanh toán"
                open={paymentConfirmModal.visible}
                onOk={onConfirmPayment}
                onCancel={onCancelPayment}
                confirmLoading={loading}
            >
                <p>
                    Bạn có chắc chắn muốn thay đổi trạng thái thanh toán từ{' '}
                    <strong>{paymentStatuses.find(s => s.value === paymentConfirmModal.currentStatus)?.label}</strong>
                    {' '}sang{' '}
                    <strong>{paymentStatuses.find(s => s.value === paymentConfirmModal.newStatus)?.label}</strong>?
                </p>
            </Modal>
        </>
    );
};

export default OrderModals; 