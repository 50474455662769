import React, { useEffect } from 'react';
import { Typography, Space } from 'antd';

const { Title } = Typography;

const PageHeader = ({ 
    title, 
    subtitle,
    extra 
}) => {
    useEffect(() => {
        document.title = title + ' - Admin Dashboard';
    }, [title]);

    return (
        <div style={{
            marginBottom: 32,
            paddingBottom: 24,
            borderBottom: '1px solid #f0f0f0'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Space direction="vertical" size={8}>
                    <Title level={3} style={{ 
                        margin: 0,
                        fontSize: '24px',
                        fontWeight: 500
                    }}>
                        {title}
                    </Title>
                    {subtitle && (
                        <Typography.Text type="secondary" style={{ fontSize: '14px' }}>
                            {subtitle}
                        </Typography.Text>
                    )}
                </Space>
                {extra && <div>{extra}</div>}
            </div>
        </div>
    );
};

export default PageHeader; 