import React, { useState } from 'react';
import { Table, InputNumber, Button, Space, Tag, Typography, message } from 'antd';
import { formatCurrency } from '../../utils/format';

const { Text } = Typography;

const InventoryTable = ({
    products,
    loading,
    variationLoading,
    tableParams,
    onTableChange,
    onUpdateStock,
    expandedRows,
    onExpand,
}) => {
    const [tempStocks, setTempStocks] = useState({});

    const handleStockChange = (productId, value, variationId = null) => {
        const key = variationId ? `${productId}-${variationId}` : productId;
        setTempStocks(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleSaveStock = async (productId, variationId = null) => {
        const key = variationId ? `${productId}-${variationId}` : productId;
        const newStock = tempStocks[key];
        
        try {
            await onUpdateStock(productId, newStock, variationId);
            setTempStocks(prev => {
                const newTemp = { ...prev };
                delete newTemp[key];
                return newTemp;
            });
        } catch (error) {
            message.error('Không thể cập nhật tồn kho');
        }
    };

    const handleCancelEdit = (productId, variationId = null) => {
        const key = variationId ? `${productId}-${variationId}` : productId;
        setTempStocks(prev => {
            const newTemp = { ...prev };
            delete newTemp[key];
            return newTemp;
        });
    };

    const columns = [
        {
            title: 'Mã SP',
            dataIndex: 'sku',
            key: 'sku',
            render: (sku) => <Text>{sku}</Text>,
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <>
                    <Text>{name.length > 50 ? `${name.substring(0, 50)}...` : name}</Text>
                    {record.type === 'variable' && (
                        <Tag color="blue" style={{ marginLeft: 8 }}>Có biến thể</Tag>
                    )}
                </>
            ),
        },
        {
            title: 'Danh mục',
            dataIndex: 'categories',
            key: 'categories',
            render: (categories) => categories?.map(cat => cat.name).join(', '),
        },
        {
            title: 'Giá bán',
            dataIndex: 'regular_price',
            key: 'price',
            render: (price, record) => {
                if (record.type === 'variable') {
                    if (!record.variations?.length) {
                        return <Text type="secondary">Đang tải...</Text>;
                    }
                    const prices = record.variations.map(v => parseFloat(v.regular_price) || 0);
                    const minPrice = Math.min(...prices);
                    const maxPrice = Math.max(...prices);
                    return `${formatCurrency(minPrice)} - ${formatCurrency(maxPrice)}`;
                }
                return formatCurrency(price);
            },
        },
        {
            title: 'Tồn WEB',
            dataIndex: 'stock_quantity',
            key: 'stock',
            render: (stock, record) => {
                if (record.type === 'variable') {
                    if (!record.variations?.length) {
                        return <Text type="secondary">Đang tải...</Text>;
                    }
                    const totalStock = record.variations.reduce((sum, var_) => 
                        sum + (parseInt(var_.stock_quantity) || 0), 0);
                    return <Text>{totalStock}</Text>;
                }
                
                const key = record.id;
                const currentValue = tempStocks[key] ?? stock;
                
                return (
                    <Space>
                        <InputNumber
                            min={0}
                            value={currentValue}
                            onChange={(value) => handleStockChange(record.id, value)}
                        />
                        {tempStocks[key] !== undefined && (
                            <Space>
                                <Button 
                                    type="primary" 
                                    size="small"
                                    onClick={() => handleSaveStock(record.id)}
                                >
                                    Lưu
                                </Button>
                                <Button 
                                    size="small"
                                    onClick={() => handleCancelEdit(record.id)}
                                >
                                    Hủy
                                </Button>
                            </Space>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'Tồn thực tế',
            dataIndex: 'actual_stock',
            key: 'actual_stock',
            render: (_, record) => {
                if (record.type === 'variable') {
                    if (!record.variations?.length) {
                        return <Text type="secondary">Đang tải...</Text>;
                    }
                    // Tổng tồn kho thực tế của các biến thể
                    return <Text>-</Text>; // Tạm thời hiển thị "-"
                }
                return <Text>-</Text>; // Tạm thời hiển thị "-"
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'stock_status',
            key: 'status',
            render: (status, record) => {
                if (record.type === 'variable') {
                    if (!record.variations?.length) {
                        return <Text type="secondary">Đang tải...</Text>;
                    }
                    const hasInStock = record.variations.some(var_ => var_.stock_status === 'instock');
                    const hasOutOfStock = record.variations.some(var_ => var_.stock_status === 'outofstock');
                    
                    if (hasInStock && hasOutOfStock) {
                        return <Tag color="gold">Còn một số biến thể</Tag>;
                    }
                    return <Tag color={hasInStock ? 'green' : 'red'}>
                        {hasInStock ? 'Còn hàng' : 'Hết hàng'}
                    </Tag>;
                }

                const color = status === 'instock' ? 'green' : 'red';
                const text = status === 'instock' ? 'Còn hàng' : 'Hết hàng';
                return <Tag color={color}>{text}</Tag>;
            },
        },
    ];

    const expandedRowRender = (record) => {
        if (record.type !== 'variable' || !record.variations?.length) {
            return null;
        }

        const variationColumns = [
            {
                title: 'Thuộc tính',
                dataIndex: 'attributes',
                key: 'attributes',
                render: (attributes) => (
                    attributes?.map(attr => `${attr.name}: ${attr.option}`).join(', ')
                ),
            },
            {
                title: 'Mã biến thể',
                dataIndex: 'sku',
                key: 'sku',
                width: 120,
            },
            {
                title: 'Giá bán',
                dataIndex: 'regular_price',
                key: 'price',
                width: 120,
                render: (price) => formatCurrency(price),
            },
            {
                title: 'Tồn kho',
                dataIndex: 'stock_quantity',
                key: 'stock',
                width: 200,
                render: (stock, variation) => {
                    const key = `${record.id}-${variation.id}`;
                    const currentValue = tempStocks[key] ?? stock;
                    
                    return (
                        <Space>
                            <InputNumber
                                min={0}
                                value={currentValue}
                                onChange={(value) => handleStockChange(record.id, value, variation.id)}
                            />
                            {tempStocks[key] !== undefined && (
                                <Space>
                                    <Button 
                                        type="primary" 
                                        size="small"
                                        onClick={() => handleSaveStock(record.id, variation.id)}
                                    >
                                        Lưu
                                    </Button>
                                    <Button 
                                        size="small"
                                        onClick={() => handleCancelEdit(record.id, variation.id)}
                                    >
                                        Hủy
                                    </Button>
                                </Space>
                            )}
                        </Space>
                    );
                },
            },
            {
                title: 'Tồn kho thực tế',
                dataIndex: 'actual_stock',
                key: 'actual_stock',
                width: 120,
                render: () => <Text>-</Text>, // Tạm thời hiển thị "-"
            },
            {
                title: 'Trạng thái',
                dataIndex: 'stock_status',
                key: 'status',
                width: 120,
                render: (status) => {
                    const color = status === 'instock' ? 'green' : 'red';
                    const text = status === 'instock' ? 'Còn hàng' : 'Hết hàng';
                    return <Tag color={color}>{text}</Tag>;
                },
            },
        ];

        return (
            <Table
                columns={variationColumns}
                dataSource={record.variations}
                pagination={false}
                rowKey="id"
                loading={variationLoading[record.id]}
            />
        );
    };

    return (
        <Table
            columns={columns}
            dataSource={products}
            loading={loading}
            pagination={tableParams.pagination}
            onChange={onTableChange}
            rowKey="id"
            expandable={{
                expandedRowRender,
                expandedRowKeys: expandedRows,
                onExpand: onExpand,
                rowExpandable: (record) => record.type === 'variable',
            }}
        />
    );
};

export default InventoryTable; 