import React, { useState } from 'react';
import { Modal, Table, Tag, Input, Button, Form, DatePicker, message } from 'antd';
import dayjs from 'dayjs';

const UserDetailsModal = ({ visible, user, onClose, onUpdateUser, loading }) => {
    const [editingKey, setEditingKey] = useState('');
    const [form] = Form.useForm();

    if (!user) return null;

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        // Với trường date, cần convert sang dayjs object
        const value = record.type === 'date' && record.value 
            ? dayjs(record.value) 
            : record.value;
            
        form.setFieldsValue({ [record.key]: value });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (record) => {
        try {
            const value = await form.validateFields();
            const newValue = value[record.key];

            let updateData = {};
            const paths = record.path.split('.');
            
            if (paths[0] === 'details') {
                updateData.details = {};
                if (paths[1] === 'personal_info') {
                    updateData.details.personal_info = {
                        [record.key]: record.type === 'date' 
                            ? dayjs(newValue).format('YYYY-MM-DD') 
                            : newValue
                    };
                } else if (paths[1] === 'address') {
                    updateData.details.address = {
                        [record.key]: newValue
                    };
                } else if (paths[1] === 'bank') {
                    updateData.details.bank = {
                        [record.key]: newValue
                    };
                } else {
                    updateData.details[record.key] = newValue;
                }
            } else {
                updateData[record.key] = newValue;
            }

            await onUpdateUser(user.ID, updateData);
            setEditingKey('');
        } catch (error) {
            console.error('Save error:', error);
        }
    };

    const columns = [
        {
            title: 'Field',
            dataIndex: 'field',
            width: '25%',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: '55%',
            render: (text, record) => {
                const editable = isEditing(record);
                if (editable) {
                    return (
                        <Form.Item
                            name={record.key}
                            style={{ margin: 0 }}
                            rules={[{ required: record.required, message: `${record.field} is required!` }]}
                        >
                            {record.type === 'date' ? (
                                <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    );
                }

                if (record.type === 'date') {
                    return text ? dayjs(text).format('DD/MM/YYYY') : 'N/A';
                }
                return text || 'N/A';
            }
        },
        {
            title: 'Action',
            width: '20%',
            render: (_, record) => {
                const editable = isEditing(record);
                if (record.readonly) return null;
                
                return editable ? (
                    <span>
                        <Button
                            type="link"
                            onClick={() => save(record)}
                            style={{ marginRight: 8 }}
                            size="small"
                        >
                            Save
                        </Button>
                        <Button 
                            type="link" 
                            onClick={cancel}
                            size="small"
                        >
                            Cancel
                        </Button>
                    </span>
                ) : (
                    <Button 
                        type="link" 
                        disabled={editingKey !== ''} 
                        onClick={() => edit(record)}
                        size="small"
                    >
                        Edit
                    </Button>
                );
            }
        }
    ];

    const data = [
        // { key: 'ID', field: 'ID', value: user.ID, readonly: true },
        { key: 'custom_user_id', field: 'ID', value: user.details.custom_user_id, readonly: true },
        { key: 'display_name', field: 'Họ tên', value: user.display_name, required: true },
        { key: 'user_email', field: 'Email', value: user.user_email, required: true },
        { key: 'user_phone', field: 'Số điện thoại', value: user.details.user_phone, required: true, path: 'details' },
        { key: 'parent_id', field: 'ID cấp trên', value: user.details.parent.id, readonly: true },
        { key: 'parent_name', field: 'Tên cấp trên', value: user.details.parent.name, readonly: true },
        { 
            key: 'dob', 
            field: 'Ngày sinh', 
            value: user.details.personal_info.dob, 
            type: 'date',
            path: 'details.personal_info'
        },
        { 
            key: 'id_number', 
            field: 'Số CMND/CCCD', 
            value: user.details.personal_info.id_number,
            path: 'details.personal_info'
        },
        { 
            key: 'cccd_issue_date', 
            field: 'Ngày cấp', 
            value: user.details.personal_info.cccd_issue_date, 
            type: 'date',
            path: 'details.personal_info'
        },
        { 
            key: 'cccd_issue_place', 
            field: 'Nơi cấp', 
            value: user.details.personal_info.cccd_issue_place,
            path: 'details.personal_info'
        },
        { 
            key: 'occupation', 
            field: 'Nghề nghiệp', 
            value: user.details.personal_info.occupation,
            path: 'details.personal_info'
        },
        { 
            key: 'permanent', 
            field: 'Địa chỉ thường trú', 
            value: user.details.address.permanent,
            path: 'details.address'
        },
        { 
            key: 'temporary', 
            field: 'Địa chỉ tạm trú', 
            value: user.details.address.temporary,
            path: 'details.address'
        },
        { 
            key: 'name', 
            field: 'Tên ngân hàng', 
            value: user.details.bank.name,
            path: 'details.bank'
        },
        { 
            key: 'branch', 
            field: 'Chi nhánh', 
            value: user.details.bank.branch,
            path: 'details.bank'
        },
        { 
            key: 'account_number', 
            field: 'Số tài khoản', 
            value: user.details.bank.account_number,
            path: 'details.bank'
        },
        { 
            key: 'account_name', 
            field: 'Tên chủ tài khoản', 
            value: user.details.bank.account_name,
            path: 'details.bank'
        }
    ];

    return (
        <Modal
            title="Chi tiết người dùng"
            open={visible}
            onCancel={onClose}
            width={800}
            footer={null}
        >
            <Form form={form}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    rowKey="field"
                    size="small"
                    bordered
                />
            </Form>
        </Modal>
    );
};

export default UserDetailsModal; 