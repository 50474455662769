import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Typography, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login } from '../api'; // Gọi hàm login từ api.js
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Login = ({ setIsLoggedIn }) => { // Nhận setIsLoggedIn từ props
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Đăng Nhập"; 
    }, []);

    const onFinish = async (values) => {
        setLoading(true);
    
        try {
            const response = await login(values); // Gọi API login
    
            if (response.token) {
                const { user_id, username, email, roles } = response;
    
                // Kiểm tra nếu người dùng có quyền 'administrator'
                if (roles && roles.includes('administrator')) {
                    message.success('Đăng nhập thành công!');
                    
                    // Lưu thông tin vào localStorage (chỉ lưu thông tin cần thiết, không lưu trạng thái đăng nhập)
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('user_id', user_id);
                    localStorage.setItem('user_login', username);
                    localStorage.setItem('user_email', email);                       
                    localStorage.setItem('roles', roles[0]);                 
    
                    // Cập nhật trạng thái đăng nhập
                    setIsLoggedIn(true);
    
                    // Điều hướng đến trang chính
                    navigate('/', { replace: true });
                } else {
                    // Nếu người dùng không phải là 'administrator'
                    message.error('Bạn không có quyền truy cập. Chỉ có quyền quản trị viên mới được phép truy cập.');
                    setIsLoggedIn(false); // Không cho phép đăng nhập
                }
            } else {
                message.error(response.message || 'Đăng nhập thất bại.');
            }
        } catch (error) {
            message.error(`Có lỗi xảy ra: ${error.message}`);
        } finally {
            setLoading(false); // Đặt loading về false ở đây
        }
    };

    return (
        <Row justify="center" style={{ marginTop: '50px' }}>
            <Col xs={24} sm={20} md={16} lg={8} xl={6}>
                <Title level={2} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Đăng Nhập
                </Title>
                <Form
                    name="login"
                    onFinish={onFinish}
                    layout="vertical"
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Vui lòng nhập Email hoặc số điện thoại!' }]}
                    >
                        <Input 
                            prefix={<UserOutlined />} 
                            placeholder="Email hoặc số điện thoại" 
                            size="large" 
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                    >
                        <Input.Password 
                            prefix={<LockOutlined />} 
                            placeholder="Mật khẩu" 
                            size="large" 
                        />
                    </Form.Item>
                    <Form.Item>
                        <Row justify="space-between">
                            <Col>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Ghi nhớ tài khoản</Checkbox>
                                </Form.Item>
                            </Col>                         
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Button 
                            type="primary" 
                            htmlType="submit" 
                            block 
                            size="large" 
                            loading={loading}
                        >
                            Đăng nhập
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default Login;
