import axios from 'axios'; // Nhập axios

const host = 'https://lotilife.com/wp-json'; // Tạo biến host

export const login = async (values) => {
    try {
        const response = await axios.post(`${host}/my-affiliate/v1/login`, values, {
            headers: {
                'Content-Type': 'application/json',
            },            
        });
        return response.data; // Trả về dữ liệu từ phản hồi
    } catch (error) {
        console.error('Login error:', error);
        throw new Error(error.response?.data?.message || 'Login failed'); // Trả về thông báo lỗi
    }
};

export const logout = async () => {
    try {
        const response = await axios.post(`${host}/my-affiliate/v1/logout`, {}, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true // Gửi cookie kèm theo yêu cầu
        });
        return response.data; // Trả về dữ liệu từ phản hồi
    } catch (error) {
        console.error('Logout error:', error);
        throw new Error(error.response?.data?.message || 'Logout failed'); // Trả về thông báo lỗi
    }
};

export const checkAuthStatus = async (token) => {
    try {
        const response = await axios.post(`${host}/jwt-auth/v1/token/validate`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Trả về dữ liệu phản hồi từ API
    } catch (error) {
        console.error('Auth status check failed:', error);
        throw error;
    }
};

// Thêm hàm lấy danh sách thành viên với xác thực JWT
export const fetchMembers = async (token, params) => {
    try {
        const response = await axios.get(`${host}/my-affiliate/v1/members`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: params // params.type = 'all' hoặc 'f0'
        });
        return response.data;
    } catch (error) {
        console.error('Fetch members error:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch members');
    }
};

export const fetchOrders = async (token, params) => {
    try {
        const response = await axios.get(`${host}/my-affiliate/v1/orders`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: {
                page: params.page,
                per_page: params.per_page,
                status: params.status,
                search: params.search,
                start_date: params.start_date,
                end_date: params.end_date,
                is_paid: params.is_paid
            }
        });

        return {
            data: response.data.data,
            total: response.data.total,
            totalPages: response.data.totalPages,
            perPage: response.data.perPage,
            currentPage: response.data.currentPage
        };
    } catch (error) {
        console.error('Fetch orders error:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch orders');
    }
};

export const fetchOrderDetail = async (token, orderId) => {
    try {
        const response = await axios.get(`${host}/wc/v3/orders/${orderId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Fetch order detail error:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch order detail');
    }
};
export const updateOrderStatus = async (token, orderId, status) => {
    try {
        const response = await axios.put(`${host}/wc/v3/orders/${orderId}`, 
            { status },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Update order status error:', error);
        throw new Error(error.response?.data?.message || 'Failed to update order status');
    }
};
export const getSystemCommission = async (token, month) => {
  try {
    const response = await axios.post(`${host}/my-affiliate/v1/system-commission`, { month }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching system commission:', error);
    throw error;
  }
};

// Thêm hàm mới để xuất dữ liệu ra Excel
export const exportToExcel = async (token, month) => {
  try {
    const response = await axios.post(`${host}/my-affiliate/v1/export-commission`, { month }, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob', // Quan trọng để nhận file
    });
    
    // Tạo một Blob từ dữ liệu nhận được
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    // Tạo một URL cho Blob
    const downloadUrl = window.URL.createObjectURL(blob);
    
    // Tạo một thẻ a ẩn và kích hoạt sự kiện click để tải file
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `commission_${month}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Giải phóng URL object
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error exporting to Excel:', error);
    throw error;
  }
};

// Thêm hàm mới để tính hoa hồng
export const calculateCommission = async (token, month) => {
  try {
    const response = await axios.post(`${host}/my-affiliate/v1/calculate-commission`, { month }, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error calculating commission:', error);
    throw error;
  }
};

export const updateUser = async (token, userId, userData) => {
    try {
        const response = await axios.put(
            `${host}/my-affiliate/v1/users/${userId}`,
            userData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Update user error:', error);
        throw new Error(error.response?.data?.message || 'Failed to update user');
    }
};

export const updateUserStatus = async (token, userId, status) => {
    try {
        const response = await axios.put(
            `${host}/my-affiliate/v1/users/${userId}/status`,
            { status },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Update user status error:', error);
        throw new Error(error.response?.data?.message || 'Failed to update user status');
    }
};

export const deleteOrder = async (token, orderId, force = true) => {
    try {
        const response = await axios.delete(`${host}/wc/v3/orders/${orderId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: {
                force // true sẽ xóa vĩnh viễn, false sẽ chuyển vào thùng rác
            }
        });
        return response.data;
    } catch (error) {
        console.error('Delete order error:', error);
        throw new Error(error.response?.data?.message || 'Failed to delete order');
    }
};

// Thêm các hàm API mới
export const fetchProducts = async (token, params) => {
    try {
        const response = await axios.get(`${host}/wc/v3/products`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params,
        });
        return {
            data: response.data,
            total: parseInt(response.headers['x-wp-total']),
            totalPages: parseInt(response.headers['x-wp-totalpages']),
        };
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Failed to fetch products');
    }
};

export const updateProductStock = async (token, productId, newStock, variationId = null) => {
    const endpoint = variationId 
        ? `${host}/wc/v3/products/${productId}/variations/${variationId}`
        : `${host}/wc/v3/products/${productId}`;

    try {
        const response = await axios.put(endpoint, 
            { 
                stock_quantity: newStock,
                manage_stock: true
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating stock:', error);
        throw new Error(error.response?.data?.message || 'Failed to update stock');
    }
};


export const fetchProductVariations = async (token, productId) => {
    const response = await axios.get(`${host}/wc/v3/products/${productId}/variations`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            per_page: 100 // Hoặc số lượng phù hợp với yêu cầu
        }
    });
    return response.data;
};

export const updatePaymentStatus = async (token, orderId, status) => {
    try {
        const response = await axios.put(
            `${host}/my-affiliate/v1/orders/${orderId}/payment-status`,
            { status },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Update payment status error:', error);
        throw new Error(error.response?.data?.message || 'Failed to update payment status');
    }
};

export const updateVatStatus = async (token, orderId, status) => {   
    try {
        const response = await axios.put(
            `${host}/wc/v3/orders/${orderId}`, 
            { 
                meta_data: [
                    {
                        key: 'is_vat_exempt',
                        value: status
                    }
                ]
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            }
        );

        console.log('API Response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Update VAT status error:', error);
        throw new Error(error.response?.data?.message || 'Failed to update VAT status');
    }
};

export const fetchMemberTree = async (token, userId) => {
    const response = await axios.get(`${host}/my-affiliate/v1/member-tree/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
};