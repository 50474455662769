import React from 'react';
import { Input, Select, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const OrderFilters = ({ 
    searchQuery, 
    statusFilter, 
    loading,
    orderStatuses,
    paymentStatuses,
    onSearchChange,
    onSearch,
    onStatusFilterChange,
    onPaymentStatusFilterChange,
    onDateRangeChange
}) => {
    const handlePresetChange = (value) => {
        let dates;
        switch(value) {
            case 'today':
                dates = [dayjs(), dayjs()];
                break;
            case 'yesterday':
                dates = [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')];
                break;
            case 'last7days':
                dates = [dayjs().subtract(6, 'day'), dayjs()];
                break;
            case 'last30days':
                dates = [dayjs().subtract(29, 'day'), dayjs()];
                break;
            case 'thisMonth':
                dates = [dayjs().startOf('month'), dayjs().endOf('month')];
                break;
            case 'lastMonth':
                dates = [
                    dayjs().subtract(1, 'month').startOf('month'),
                    dayjs().subtract(1, 'month').endOf('month')
                ];
                break;
            default:
                dates = [dayjs().subtract(6, 'day'), dayjs()];
        }
        onDateRangeChange(dates);
    };

    return (
        <div style={{ marginBottom: '16px' }}>
            <Search 
                placeholder="Tìm kiếm đơn hàng" 
                value={searchQuery}
                onChange={(e) => onSearchChange(e.target.value)}
                onSearch={onSearch}
                enterButton 
                loading={loading}
                style={{ width: '300px', marginRight: '16px' }}
            />
            <Select 
                value={statusFilter} 
                placeholder="Trạng thái đơn hàng"
                onChange={onStatusFilterChange} 
                style={{ width: '150px', marginRight: '16px' }}
            >
                <Option value="any">Tất cả trạng thái</Option>
                {orderStatuses.map(status => (
                    <Option key={status.value} value={status.value}>
                        {status.label}
                    </Option>
                ))}
            </Select>            
            <Select 
                placeholder="Trạng thái thanh toán"
                onChange={onPaymentStatusFilterChange}
                style={{ width: '180px', marginRight: '16px' }}
                allowClear
            >
                {paymentStatuses && paymentStatuses.map(status => (
                    <Option key={status.value} value={status.value}>
                        {status.label}
                    </Option>
                ))}
            </Select>
            <Space>
                <Select
                    defaultValue="last7days"
                    style={{ width: 150 }}
                    onChange={handlePresetChange}
                >
                    <Option value="today">Hôm nay</Option>
                    <Option value="yesterday">Hôm qua</Option>
                    <Option value="last7days">7 ngày qua</Option>
                    <Option value="last30days">30 ngày qua</Option>
                    <Option value="thisMonth">Tháng này</Option>
                    <Option value="lastMonth">Tháng trước</Option>                    
                </Select>

                
            </Space>
            {/* <RangePicker
                onChange={onDateRangeChange}
                style={{ marginRight: '16px' }}
                format="DD-MM-YYYY"
                defaultValue={[dayjs().subtract(6, 'day'), dayjs()]}
            /> */}
        </div>
    );
};

export default OrderFilters; 