import React, { useEffect, useState, useMemo } from 'react'; 
import { Layout, Breadcrumb, Spin, Typography, Avatar, Dropdown, Space } from 'antd';
import { HashRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Analytics from './pages/Analytics';
import UserPage from './pages/Users'
import OrdersList from './pages/OrdersList'
import UserTree from './pages/Tree'
import MenuSidebar from './components/Menu'; 
import { MenuFoldOutlined, MenuUnfoldOutlined, BellOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { checkAuthStatus } from './api';
import Commission from './pages/Commission';
import { Link } from 'react-router-dom';
import Inventory from './pages/Inventory';
import { AuthProvider } from './contexts/AuthContext';
import { logout } from './api';
import RevenueAnalytics from './pages/Analytics/Revenue';
import OrdersAnalytics from './pages/Analytics/Orders';
import ProductsAnalytics from './pages/Analytics/Products';
import VariationsAnalytics from './pages/Analytics/Variations';
import StatsOverview from './pages/Analytics/Overview';

const { Header, Content, Sider } = Layout;
const { Text } = Typography;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true); // Thêm loading state cho quá trình xác thực
  const [collapsed, setCollapsed] = useState(false); 
  const navigate = useNavigate(); 
  const location = useLocation();

  const breadcrumbNameMap = {
    '/dashboard': 'Dashboard',
    '/analytics': 'Thống kê',
    '/analytics/overview': 'Tổng quan',
    '/analytics/revenue': 'Doanh thu',
    '/analytics/orders': 'Đơn hàng',
    '/analytics/products': 'Sản phẩm',
    '/analytics/variations': 'Biến thể',
    '/orders': 'Đơn hàng',    
    '/users': 'Người dùng',    
    '/users/tree': 'Cây tổ chức',    
    '/commission': 'Hoa hồng',
    '/inventory': 'Kho hàng',
    '/inventory/stock-history': 'Lịch sử kho',
  };

  const breadcrumbItems = useMemo(() => {
    const pathSnippets = location.pathname.split('/').filter(i => i);
    const items = [
      {
        title: <Link to="/dashboard">Dashboard</Link>,
        key: 'dashboard'
      }
    ];

    let url = '';
    pathSnippets.forEach((_, index) => {
      url += `/${pathSnippets[index]}`;      
      if (breadcrumbNameMap[url]) {
        items.push({
          title: index === pathSnippets.length - 1 ? 
            breadcrumbNameMap[url] : 
            <Link to={url}>{breadcrumbNameMap[url]}</Link>,
          key: url
        });
      }
    });

    return items;
  }, [location.pathname]);

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    setIsLoggedIn(false);
    navigate('/login');
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token'); 

      if (token) {
        try {
          const response = await checkAuthStatus(token); 
          if (response.data.status === 200) {
            setIsLoggedIn(true); 
          } else {
            setIsLoggedIn(false);
            localStorage.removeItem('token'); 
            navigate('/login'); 
          }
        } catch (error) {
          console.error('Error checking auth status:', error);
          setIsLoggedIn(false);
          navigate('/login');
        }
      } else {
        setIsLoggedIn(false); 
        navigate('/login');
      }
      setLoadingAuth(false); // Hoàn tất quá trình kiểm tra, tắt loading
    };

    checkAuth(); 
  }, [navigate]);

  const userMenuItems = [    
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
      danger: true,
      onClick: handleLogout,
    },
  ];

  if (loadingAuth) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#f0f2f5'
      }}>
        <Spin 
          size="large"
          tip="Đang xác thực, vui lòng đợi..."
        />
      </div>
    );
  }

  return (
    <AuthProvider>
      <Layout style={{ minHeight: '100vh' }}>
        {isLoggedIn && (
          <Sider 
            theme='light' 
            width={260} 
            className="site-layout-background" 
            collapsible
            collapsed={collapsed} 
            onCollapse={setCollapsed}
            style={{
              boxShadow: '2px 0 8px 0 rgba(29,35,41,.05)',
              zIndex: 999,
            }}
          >
            <div className="logo" style={{
              height: '64px',
              padding: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: collapsed ? 'center' : 'flex-start',
              borderBottom: '1px solid #f0f0f0'
            }}>
              
              {!collapsed && <Text strong style={{ marginLeft: '12px', fontSize: '18px' }}>LOTI ADMIN</Text>}
            </div>
            <MenuSidebar 
              hasChild={true} 
              setIsLoggedIn={setIsLoggedIn}
              collapsed={collapsed}
            />
          </Sider>
        )}
        <Layout>
          {isLoggedIn && (
            <Header style={{ 
              padding: '0 24px', 
              background: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              boxShadow: '0 1px 4px rgba(0,21,41,.08)',
              position: 'sticky',
              top: 0,
              zIndex: 100,
              height: '64px'
            }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span 
                  style={{ cursor: 'pointer', fontSize: '16px' }} 
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </span>
                <Breadcrumb 
                  items={breadcrumbItems}
                  separator=">"
                  style={{ margin: '0 24px' }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <BellOutlined style={{ fontSize: '16px', cursor: 'pointer' }} />
                <Dropdown menu={{ items: userMenuItems }} placement="bottomRight">
                  <Space style={{ cursor: 'pointer' }}>
                    <Avatar size="small" icon={<UserOutlined />} />
                    <Text>{localStorage.getItem('user_login') || 'Admin'}</Text>
                  </Space>
                </Dropdown>
              </div>
            </Header>
          )}
          <Content style={{ 
            margin: '24px',
            minHeight: 280,
            borderRadius: '4px',
            overflow: 'hidden'
          }}>
            {isLoggedIn ? (
              <div style={{ 
                padding: '24px',
                background: '#fff',
                minHeight: 360,
                borderRadius: '4px',
                boxShadow: '0 1px 2px 0 rgba(0,0,0,0.03)'
              }}>
                <Routes>
                  <Route path="/" element={<UserPage />} />
                  <Route path="/analytics" element={<Analytics />}>
                    <Route index element={<StatsOverview />} />
                    <Route path="revenue" element={<RevenueAnalytics />} />
                    <Route path="orders" element={<OrdersAnalytics />} />
                    <Route path="products" element={<ProductsAnalytics />} />
                    <Route path="variations" element={<VariationsAnalytics />} />
                  </Route>
                  <Route path="/users" element={<UserPage />} />
                  <Route path="/users/tree" element={<UserTree />} />
                  <Route path="/orders" element={<OrdersList />} />
                  <Route path="/commission" element={<Commission />} />
                  <Route path="/inventory" element={<Inventory />} />                    
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </div>
            ) : (
              <Routes>
                <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            )}
          </Content>
        </Layout>
      </Layout>
    </AuthProvider>
  );
}

export default App;
