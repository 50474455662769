import React, { useState } from 'react';
import { Button, message, Modal, Checkbox, Select, Space, Row, Col, Divider, DatePicker } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const DATE_RANGES = [
    { label: '7 ngày qua', value: 7 },
    { label: '30 ngày qua', value: 30 },
    { label: '90 ngày qua', value: 90 },
    { label: 'Tùy chọn', value: -1 },
    { label: 'Tất cả', value: 0 }
];

const STATUS_OPTIONS = [
    { label: 'Tất cả', value: 'any' },
    { label: 'Đang xử lý', value: 'processing' },
    { label: 'Đã hoàn thành', value: 'completed' },
    { label: 'Đã hủy', value: 'cancelled' }
];

const getDateRangeInfo = (selectedDays, customDateRange) => {
    if (selectedDays === -1 && customDateRange && customDateRange[0] && customDateRange[1]) {
        const startDate = dayjs(customDateRange[0]);
        const endDate = dayjs(customDateRange[1]);                
        if (startDate.isSame(endDate, 'day')) {
            return `ngày ${startDate.format('DD/MM/YYYY')}`;
        }                
        if (startDate.isSame(dayjs(), 'day') && endDate.isSame(dayjs(), 'day')) {
            return 'hôm nay';
        }                
        if (startDate.isSame(dayjs().subtract(1, 'day'), 'day') && 
            endDate.isSame(dayjs().subtract(1, 'day'), 'day')) {
            return 'hôm qua';
        }                
        return `từ ${startDate.format('DD/MM/YYYY')} đến ${endDate.format('DD/MM/YYYY')}`;
    } else if (selectedDays > 0) {
        return `${selectedDays} ngày qua`;
    }
    
    return 'tất cả thời gian';
};

const getExportFilename = (selectedDays, customDateRange) => {
    if (selectedDays === -1 && customDateRange && customDateRange[0] && customDateRange[1]) {
        const startDate = dayjs(customDateRange[0]);
        const endDate = dayjs(customDateRange[1]);                
        if (startDate.isSame(endDate, 'day')) {
            return `orders-${startDate.format('DD-MM-YYYY')}.xlsx`;
        }
                
        if (startDate.isSame(dayjs(), 'day') && endDate.isSame(dayjs(), 'day')) {
            return `orders-today-${dayjs().format('DD-MM-YYYY')}.xlsx`;
        }                
        if (startDate.isSame(dayjs().subtract(1, 'day'), 'day') && 
            endDate.isSame(dayjs().subtract(1, 'day'), 'day')) {
            return `orders-yesterday-${startDate.format('DD-MM-YYYY')}.xlsx`;
        }                
        return `orders-${startDate.format('DD-MM-YYYY')}-to-${endDate.format('DD-MM-YYYY')}.xlsx`;
    } else if (selectedDays > 0) {
        return `orders-${selectedDays}days-${dayjs().format('DD-MM-YYYY')}.xlsx`;
    }
    
    return `orders-all-${dayjs().format('DD-MM-YYYY')}.xlsx`;
};

const ExportExcel = ({ fetchData, columns }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(
        columns.map(col => col.key)
    );
    const [selectedDays, setSelectedDays] = useState(7);
    const [customDateRange, setCustomDateRange] = useState(null);
    const [statusFilter, setStatusFilter] = useState('any');
    const [loading, setLoading] = useState(false);

    const handleDateRangeChange = (value) => {
        if (value === -1) {
            setCustomDateRange(null);
        }
        setSelectedDays(value);
    };

    const handleExport = async () => {
        try {
            setLoading(true);
            
            const params = {
                page: 1,
                per_page: 999999,
                status: statusFilter !== 'any' ? statusFilter : undefined
            };
            
            if (selectedDays === -1 && customDateRange?.[0] && customDateRange?.[1]) {
                params.start_date = customDateRange[0].format('YYYY-MM-DD');
                params.end_date = customDateRange[1].format('YYYY-MM-DD');
            } else if (selectedDays > 0) {
                params.start_date = dayjs().subtract(selectedDays, 'day').format('YYYY-MM-DD');
                params.end_date = dayjs().format('YYYY-MM-DD');
            }

            const dateRangeInfo = getDateRangeInfo(selectedDays, customDateRange);
            message.loading({
                content: `Đang xuất dữ liệu ${dateRangeInfo}. Vui lòng đợi trong giây lát...`,
                key: 'exportLoading',
                duration: 0
            });

            const exportData = await fetchData(params);
            const exportFilename = getExportFilename(selectedDays, customDateRange);
            
            // Excel generation logic
            const wb = XLSX.utils.book_new();
            const filteredColumns = columns.filter(col => selectedColumns.includes(col.key));
            const formattedData = exportData.map(record => {
                const row = {};
                filteredColumns.forEach(column => {
                    let value = record[column.dataIndex];
                    if (column.exportRender) {
                        value = column.exportRender(value, record);
                    }
                    row[column.title] = value;
                });
                return row;
            });

            const ws = XLSX.utils.json_to_sheet(formattedData);
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' 
            });
            FileSaver.saveAs(blob, exportFilename);

            message.destroy('exportLoading');
            message.success('Xuất file thành công!');
            setIsModalVisible(false);
        } catch (error) {
            console.error('Export error:', error);
            message.error('Không thể xuất file Excel');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                icon={<FileExcelOutlined />}
                onClick={() => setIsModalVisible(true)}
                type="primary"
            >
                Xuất Excel
            </Button>

            <Modal
                title="Tùy chọn xuất Excel"
                open={isModalVisible}
                onOk={handleExport}
                onCancel={() => setIsModalVisible(false)}
                okText="Xuất Excel"
                cancelText="Hủy"
                confirmLoading={loading}
                width={700}
            >
                <Row gutter={[16, 16]}>
                <Col span={12}>                        
                        <h4>Khoảng thời gian:</h4>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Select
                                style={{ width: '100%' }}
                                value={selectedDays}
                                onChange={handleDateRangeChange}
                                options={DATE_RANGES}
                            />
                            
                            {selectedDays === -1 && (
                                <RangePicker
                                    style={{ width: '100%' }}
                                    value={customDateRange}
                                    onChange={setCustomDateRange}
                                    format="DD/MM/YYYY"
                                />
                            )}
                        </Space>
                    </Col>
                    <Col span={12}>
                        <h4>Trạng thái đơn hàng:</h4>
                        <Select
                            style={{ width: '100%' }}
                            value={statusFilter}
                            onChange={setStatusFilter}
                            options={STATUS_OPTIONS}
                        />
                    </Col>
                    

                    <Col span={24}>
                        <Divider orientation="left">Chọn cột xuất Excel</Divider>
                        <Checkbox.Group 
                            value={selectedColumns}
                            onChange={setSelectedColumns}
                        >
                            <Row gutter={[16, 8]}>
                                {columns.map(column => (
                                    <Col span={8} key={column.key}>
                                        <Checkbox 
                                            value={column.key}
                                            disabled={column.required}
                                        >
                                            {column.title}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ExportExcel; 