import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Statistic } from 'antd';
import { ShoppingOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';
import AnalyticsCard from '../../components/Analytics/Overvies/AnalyticsCard';


const ProductsAnalytics = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    dateType: 'preset',
    presetDate: 'today',
    startDate: null,
    endDate: null
  });

  const columns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Số lượng bán',
      dataIndex: 'items_sold',
      key: 'items_sold',
    },
    {
      title: 'Doanh thu',
      dataIndex: 'net_revenue',
      key: 'net_revenue',
      render: (value) => new Intl.NumberFormat('vi-VN').format(value) + 'đ'
    },
    {
      title: 'Đơn hàng',
      dataIndex: 'orders_count',
      key: 'orders_count',
    }
  ];

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>      
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <AnalyticsCard title="Top sản phẩm bán chạy" loading={loading}>
            <Table 
              columns={columns}
              dataSource={data?.products}
              loading={loading}
              rowKey="product_id"
            />
          </AnalyticsCard>
        </Col>
        <Col span={24}>
          <AnalyticsCard title="Biểu đồ bán hàng theo thời gian">
            
          </AnalyticsCard>
        </Col>
      </Row>
    </div>
  );
};

export default ProductsAnalytics; 