import React, { useState, useContext } from 'react';
import { Row, Col, Card, Table, Space } from 'antd';
import { ShoppingOutlined } from '@ant-design/icons';

const TopProducts = ({ products }) => {
  const [loading, setLoading] = useState(false);
  
  const columns = [
    {
      title: 'Sản phẩm',
      dataIndex: 'product_name',
      render: (text) => (
        <Space>
          <ShoppingOutlined />
          {text}
        </Space>
      ),
    },
    {
      title: 'Đã bán',
      dataIndex: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Doanh thu',
      dataIndex: 'total_sales',
      sorter: (a, b) => a.total_sales - b.total_sales,
      render: (value) => `${value ? value.toLocaleString() : 0}đ`,
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
      <Col span={24}>
        <Card title="Top sản phẩm bán chạy">
          <Table
            loading={loading}
            columns={columns}
            dataSource={products}
            pagination={{ pageSize: 5 }}
            rowKey="product_id"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TopProducts; 